import type { BankAccountSummary } from "../interfaces"
import type { SorterResult } from "ant-design-vue/es/table/interface"

import { defineStore } from "pinia"
import { computed, type MaybeRef, readonly, ref, watch } from "vue"
import { get, set, useDebounceFn } from "@vueuse/core"
import { push } from "notivue"
import { HttpStatusCode } from "axios"

import { PER_PAGE_DEFAULT, SortOrder } from "@/interfaces"
import { useListBufferApi, useListingSort, usePagination } from "@/package/hooks"
import { getOrderBySorter } from "@/package/ui-kit"

import { bankAccountGet } from "../api/bank-account.get"
import { useBankAccountCreate } from "../hooks/use-bank-account-create"
import { useBankAccountEdit } from "../hooks/use-bank-account-edit"
import { useBankAccountDelete } from "../hooks/use-bank-account-delete"
import { useBankAccountListDelete } from "../hooks/use-bank-account-list-delete"
import { useBankAccountList } from "../hooks/use-bank-account-list"
import { useGetBankAccountSorting } from "../hooks/use-get-bank-account-sorting"
import { useSaveBankAccountSorting } from "../hooks/use-save-bank-account-sorting"

export const useBankAccountStore = defineStore("BankAccountStore", () => {
  const { pagination, setPagination, decrementTotal, currentOffset, paginationTotal } =
    usePagination()
  const buffer = ref<BankAccountSummary[]>([])
  const idList = computed(() => buffer.value.map(({ id }) => id))

  const searchQuery = ref<string>()
  const searchQueryRO = readonly(searchQuery)
  const { sortBy, sortOrder, sortUsed, setSort, resetSort } = useListingSort()

  const {
    bufferRO,
    bufferUnshift,
    bufferFilterOneConcat,
    setUpdateFn,
    bufferConcat,
    bufferRewrite,
    updateBufferFn,
    bufferReplaceByFirst,
    bufferFilterListConcatReverse
  } = useListBufferApi(buffer)

  const formattedListing = computed(() =>
    bufferRO.value.map((bankAccount) => ({ ...bankAccount, children: null }))
  )

  const {
    isListLoading,
    requestBankAccountList,
    bankAccountListingResponse,
    listingApiError,
    listingResponseStatus
  } = useBankAccountList()

  const isResourceEmpty = computed(() => get(listingResponseStatus) === HttpStatusCode.NoContent)
  const isLoadedFull = computed(() => {
    const total = get(pagination).total
    return (total !== null && total <= get(buffer).length) || get(isResourceEmpty)
  })

  watch(listingApiError, (error) => {
    if (error && error.status !== 404) {
      push.error({ message: "Неизвестная ошибка, пожалуйста сообщите поддержке!" })
    }
  })
  watch(bankAccountListingResponse, (listing) => {
    if (listing && listing.data.length > 0) {
      updateBufferFn(listing.data)
      setPagination(listing.pagination)
    } else {
      updateBufferFn([])
      setPagination({ ...get(pagination), total: 0 })
    }
  })

  const loadBufferNextPage = useDebounceFn(async () => {
    setUpdateFn(bufferConcat)
    await requestBankAccountList({
      offset: buffer.value.length,
      limit: PER_PAGE_DEFAULT,
      query: get(searchQuery) || undefined,
      order: get(sortOrder),
      orderBy: get(sortBy)
    })
  }, 100)
  const reloadBuffer = async () => {
    setUpdateFn(bufferRewrite)
    await requestBankAccountList({
      offset: 0,
      limit: get(buffer, "length"),
      query: get(searchQuery) || undefined,
      order: get(sortOrder),
      orderBy: get(sortBy)
    })
  }

  const requestSearch = async (requestedQuery: MaybeRef<string>) => {
    setPagination()
    set(searchQuery, get(requestedQuery))
    setUpdateFn(bufferRewrite)
    await requestBankAccountList({
      offset: get(pagination).offset,
      limit: get(pagination).limit,
      query: get(searchQuery) || undefined,
      order: get(sortOrder),
      orderBy: get(sortBy)
    })
  }

  const { createBankAccount, isCreating, createError, isCreateFinished } = useBankAccountCreate()

  const updateBufferAfterCreate = async () => {
    let result = null

    setUpdateFn(bufferUnshift)
    const response = await requestBankAccountList({
      offset: 0,
      limit: 1,
      query: get(searchQuery) || undefined
    })
    result = { id: response.data.value?.data[0].id, name: response.data.value?.data[0].name }
    if (get(sortUsed)) {
      await reloadBuffer()
    }
    return result
  }

  const { editBankAccount, isEditing, editError, isEditingFinished } = useBankAccountEdit()

  const updateBufferAfterEdit = async (id: string | number) => {
    if (!sortBy.value) {
      setUpdateFn(bufferFilterListConcatReverse.bind(null, [+id]))
      await requestBankAccountList({
        offset: 0,
        limit: 1,
        query: get(searchQuery) || undefined,
        order: get(sortOrder),
        orderBy: get(sortBy)
      })
    } else {
      const updatedId = Number(id)
      const updatedIndex = buffer.value.findIndex(({ id }) => updatedId === id)
      if (updatedIndex !== -1) {
        setUpdateFn(bufferReplaceByFirst.bind(null, updatedIndex))
        await requestBankAccountList({
          offset: updatedIndex,
          limit: 1,
          query: get(searchQuery) || undefined,
          order: get(sortOrder),
          orderBy: get(sortBy)
        })
      }
    }
  }

  const { deleteBankAccount, isDeleting, bankAccountDeleteError } = useBankAccountDelete()
  const deleteOne = async (id: number | string) => {
    if (isLoadedFull.value) {
      await deleteBankAccount(id)
      bufferFilterOneConcat(id as number, [])
      decrementTotal()
    } else {
      await deleteBankAccount(id)
      setUpdateFn(bufferFilterOneConcat.bind(null, Number(id)))
      await requestBankAccountList({
        offset: buffer.value.length - 1,
        limit: 1,
        query: get(searchQuery) || undefined,
        order: get(sortOrder),
        orderBy: get(sortBy)
      })
    }
  }

  const { requestDeleteBankAccountList, isListDeleting, bankAccountDeleteListError } =
    useBankAccountListDelete()
  const deleteByExcludedIdList = async (idList: MaybeRef<Array<string | number>>) => {
    await requestDeleteBankAccountList({
      exclude_ids: get(idList),
      query: get(searchQuery) || undefined
    })
    await reloadBuffer()
  }
  const deleteBySelectedIdList = async (idList: MaybeRef<Array<string | number>>) => {
    await requestDeleteBankAccountList({ include_ids: get(idList) })
    await reloadBuffer()
  }

  const loadOne = async (id: string | number) => {
    const {
      name,
      bank_name,
      active,
      correspondent_account_number,
      account_number,
      bic,
      legal_entity,
      balance,
      is_main,
      editable
    } = await bankAccountGet(id)
    bufferConcat([
      {
        id: +id,
        name,
        bank_name,
        active,
        correspondent_account_number,
        account_number,
        bic,
        legal_entity,
        balance,
        is_main,
        editable
      }
    ])
  }

  const setSortOrder = async (sorter: SorterResult) => {
    const orderBy = getOrderBySorter(sorter)
    if (orderBy !== null && typeof sorter.field === "string") {
      setSort(sorter.field, orderBy)
    } else {
      resetSort()
    }
    setPagination()
    setUpdateFn(bufferRewrite)
    await requestBankAccountList({
      offset: get(pagination, "offset"),
      limit: get(pagination, "limit"),
      query: get(searchQuery) || undefined,
      order: get(sortOrder),
      orderBy: get(sortBy)
    })
  }

  const { getSorting, isSortingLoading } = useGetBankAccountSorting()

  const getBankAccountSorting = async () => {
    const response = await getSorting()
    if (response?.sortBy && typeof response?.sortOrder === "string") {
      setSort(response?.sortBy, response?.sortOrder as SortOrder)
    } else {
      resetSort()
    }
    setPagination()
    setUpdateFn(bufferRewrite)
    await requestBankAccountList({
      offset: get(pagination, "offset"),
      limit: get(pagination, "limit"),
      query: get(searchQuery) || undefined,
      order: get(sortOrder),
      orderBy: get(sortBy)
    })
  }

  const { executeSave } = useSaveBankAccountSorting()

  const saveBankAccountSorting = async () => {
    await executeSave({ sortBy: sortBy.value, sortOrder: sortOrder.value })
  }

  const $reset = () => {
    setPagination()
    searchQuery.value = undefined
    buffer.value = []
    resetSort()
  }

  return {
    idList,
    listing: bufferRO,
    formattedListing,
    isLoadedFull,
    paginationTotal,
    currentOffset,

    searchQuery: searchQueryRO,
    requestSearch,

    isResourceEmpty,
    loadBufferNextPage,
    listingApiError,
    isListLoading,

    deleteOne,
    isDeleting,
    bankAccountDeleteError,

    deleteByExcludedIdList,
    deleteBySelectedIdList,
    isListDeleting,
    bankAccountDeleteListError,

    createBankAccount,
    isCreating,
    createError,
    isCreateFinished,
    updateBufferAfterCreate,
    editBankAccount,
    isEditing,
    editError,
    isEditingFinished,
    updateBufferAfterEdit,
    getBankAccountSorting,
    saveBankAccountSorting,
    isSortingLoading,
    sortOrder,
    sortUsed,
    loadOne,
    setSortOrder,
    $reset
  }
})
