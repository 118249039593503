<script setup lang="ts">
import { useRouter } from "vue-router"

import { ChangePasswordForm, ProfileDescription } from "@/modules/profile"
import { PhysProfileEditRouteName } from "@/router"

const router = useRouter()
</script>

<template>
  <ProfileDescription
    :style="{ marginTop: '32px' }"
    @editProfile="() => router.push({ name: PhysProfileEditRouteName })"
  />
  <AFlex vertical>
    <ATypographyTitle :level="5" :style="{ marginTop: '8px' }">Изменение пароля</ATypographyTitle>
    <ChangePasswordForm :style="{ flexGrow: 1, marginTop: '24px', maxWidth: '450px' }" />
  </AFlex>
  <RouterView />
</template>
