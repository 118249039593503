<script setup lang="ts">
import { computed, type PropType } from "vue"
import { theme, TypographyText } from "ant-design-vue"

import { type Profile, ProfileRolesMap } from "../interfaces"

const props = defineProps({
  profile: {
    type: [Object, undefined] as PropType<Profile | undefined>,
    default: undefined
  },

  short: {
    type: Boolean as PropType<boolean>,
    default: false
  }
})
const token = theme.useToken()
const profileAlt = computed(() =>
  [props.profile?.last_name[0].toUpperCase(), props.profile?.first_name[0].toUpperCase()]
    .filter(Boolean)
    .join("")
)
const fullName = computed(() =>
  [props.profile?.last_name, props.profile?.first_name, props.profile?.middle_name]
    .filter(Boolean)
    .join(" ")
)
</script>

<template>
  <div class="summary" :class="[short ? 'summary--short' : null]">
    <AAvatar
      :style="{
        backgroundColor: token.token.value.colorPrimaryBg,
        color: token.token.value.colorPrimary
      }"
      class="summary__avatar"
      shape="square"
      size="large"
      >{{ profileAlt }}</AAvatar
    >
    <template v-if="!short">
      <AText class="summary__fullname" :el="TypographyText">{{ fullName }}</AText>
      <TypographyText v-if="profile?.role !== undefined" type="secondary">{{
        ProfileRolesMap[profile.role]
      }}</TypographyText>
    </template>
  </div>
</template>

<!--suppress CssUnusedSymbol -->
<style scoped>
.summary {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 0 8px;
}

.summary--short {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

.summary__avatar {
  grid-column: 1/2;
  grid-row: 1/-1;
  align-self: center;
  justify-self: start;
}

.summary__fullname {
  text-transform: capitalize;
}
</style>
