import type { BankAccountCreate } from "../../interfaces"

import { computed } from "vue"

import type { FormValidator } from "@/package/validators"
import { bikValidator, validateCorrespondentAccount, validateRs } from "@/package/validators"

export const useRuleset = (formState: Pick<BankAccountCreate, "bic">) => {
  const paymentAccountValidator: FormValidator = (_, value: string) =>
    new Promise((resolve, reject) => {
      const [result, error] = validateRs(value, formState.bic)
      if (result) {
        resolve(undefined)
      } else {
        reject(error?.message)
      }
    })

  const correspondentAccountValidator: FormValidator = (_, value: string) =>
    new Promise((resolve, reject) => {
      const [result, error] = validateCorrespondentAccount(value, formState.bic)
      if (result) {
        resolve(undefined)
      } else {
        reject(error?.message)
      }
    })

  const ruleset: any = computed(() => ({
    name: [{ required: true, message: "Пожалуйста, введите название", whitespace: true }],
    correspondent_account_number: [
      { required: true, message: "Данные банка не получены" },
      { required: true, asyncValidator: correspondentAccountValidator }
    ],
    bank_name: [{ required: true, message: "Данные банка не получены" }],
    bic: [{ required: true, asyncValidator: bikValidator }],
    account_number: [{ required: true, asyncValidator: paymentAccountValidator }]
  }))

  return ruleset
}
