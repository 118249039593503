<script setup lang="ts">
import { computed, reactive, ref, watch } from "vue"
import { noop } from "@vueuse/core"
import { push } from "notivue"

import type { ChangePasswordRequest } from "@/modules/profile"
import { type FormValidator, passwordValidator } from "@/package/validators"
import { useForm } from "@/package/hooks"
import { DEFAULT_REQUEST_ERROR_MESSAGE } from "@/interfaces"

import { useChangePassword } from "../hooks/use-change-password"

const emit = defineEmits(["success"])
const formState = reactive<ChangePasswordRequest>({
  new_password_confirmation: "",
  current_password: "",
  new_password: ""
})

const confirmValidator: FormValidator = (_, value: string) =>
  new Promise((resolve, reject) => {
    if (formState.new_password && formState.new_password === value) {
      resolve(undefined)
    } else {
      reject("Пароли должны совпадать")
    }
  })
const ruleSet = computed(() => ({
  new_password_confirmation: [{ required: true, asyncValidator: confirmValidator }],
  current_password: [{ required: true, message: "Пароль не должен быть пустым" }],
  new_password: [{ required: true, asyncValidator: passwordValidator }]
}))
const { validate, validateInfos, resetFields } = useForm(formState, ruleSet, {
  immediate: false
})
const { changePassword, isLoading, error } = useChangePassword()
watch(error, (err) => {
  if (err) {
    push.error({ message: err.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})
const submitAction = () =>
  validate()
    .then(() =>
      changePassword(formState, () => {
        resetFields()
        push.success({ message: "Ваш пароль был успешно изменен." })
        emit("success")
      })
    )
    .catch(noop)
const showNewPassword = ref(false)
</script>

<template>
  <AForm
    name="change-password"
    :label-col="{ span: 24 }"
    :wrapper-col="{ span: 24 }"
    autocomplete="off"
    :colon="false"
    hide-required-mark
    @submit.prevent="submitAction"
  >
    <AFormItem
      name="current_password"
      label="Текущий пароль"
      v-bind="validateInfos?.current_password"
    >
      <AInputPassword
        v-model:value="formState.current_password"
        size="large"
        placeholder="Введите текущий пароль"
      />
    </AFormItem>
    <AFormItem name="new_password" label="Новый пароль" v-bind="validateInfos?.new_password">
      <AInputPassword
        v-model:value="formState.new_password"
        v-model:visible="showNewPassword"
        placeholder="Введите новый пароль"
        size="large"
      />
    </AFormItem>
    <AFormItem
      name="new_password_confirmation"
      label="Подтверждение пароля"
      v-bind="validateInfos?.new_password_confirmation"
    >
      <AInputPassword
        v-model:value="formState.new_password_confirmation"
        placeholder="Подтвердите пароль"
        size="large"
        :visible="showNewPassword"
        :visibility-toggle="false"
      />
    </AFormItem>
    <AButton
      block
      html-type="submit"
      type="primary"
      size="large"
      :loading="isLoading"
      :style="{ marginTop: '24px' }"
      >Сохранить пароль</AButton
    >
  </AForm>
</template>

<style scoped></style>
