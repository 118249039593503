<script setup lang="ts">
import type { Form } from "ant-design-vue"

import { computed, onUnmounted, type PropType, ref, watch } from "vue"
import { push } from "notivue"
import { storeToRefs } from "pinia"
import dayjs from "dayjs"

import { useOperationCategoriesStore } from "@/modules/operation-category"
import { useForm } from "@/package/hooks"
import { DEFAULT_REQUEST_ERROR_MESSAGE } from "@/interfaces"
import { isValidateError } from "@/package/util"
import type { AvailableBankAccounts } from "@/modules/operation"
import { useOperationStore } from "@/modules/operation"

import BankFormSwitch from "../BankFormSwtich/BankFormSwitch.vue"
import { useBankIntegrationPatch } from "../../../../hooks/use-bank-integration-patch"

import { useFormState } from "./hooks/use-form-state"
import { useRuleset } from "./hooks/use-ruleset"

const props = defineProps({
  getContainer: {
    type: [Function, undefined] as PropType<() => HTMLElement | null>,
    default: undefined
  },

  actionType: {
    type: String,
    default: undefined
  },

  data: {
    type: [Array, undefined] as PropType<AvailableBankAccounts | any>,
    default: undefined
  },

  integrationId: {
    type: Number,
    default: 0
  }
})

const emit = defineEmits(["success", "close"])

const { formState, requestData } = useFormState()
const ruleset = useRuleset()
const { validate, validateInfos, isValidating } = useForm(formState, ruleset)
const operationsStore = useOperationCategoriesStore()
const store = useOperationStore()
const { createError, isCreating, isCreateFinished } = storeToRefs(operationsStore)

watch([createError, isCreateFinished], ([err, finishedStatus]) => {
  if (err && finishedStatus) {
    isCreateFinished.value = false
    push.error({ message: err.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})

const importFormRef = ref<typeof Form | null>(null)

const { patchRequestData, patchBankIntegration, patchBankDataError } = useBankIntegrationPatch()

const submitAction = async () => {
  try {
    await validate()
    await patchBankIntegration(
      props.integrationId as number,
      dayjs(requestData?.value?.bank_import_from).format("DD.MM.YYYY"),
      accountsForPatch.value as Array<{ number: string; is_connected: boolean }>
    )
    if (patchRequestData.value?.success) {
      push.success({
        message: "Подключение прошло успешно"
      })
      emit("close")
      await store.updateBuffer()
    }
  } catch (err) {
    if (isValidateError(err)) {
      importFormRef.value?.scrollToField(err.errorFields[0].name, {
        block: "start",
        boundary: (parent: HTMLElement) => {
          parent.focus()
          return parent.classList.contains("ant-row")
        }
      })
    }
  }
}

watch(patchBankDataError, (error) => {
  if (error) {
    push.error({ message: error.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})

defineExpose({
  submitAction,
  isCreating: computed(() => isCreating.value || isCreateFinished.value || isValidating.value)
})

onUnmounted(() => {
  isCreateFinished.value = false
})

const accountsForPatch = ref<{ number: string; is_connected: boolean }[]>([])

const connect = (id: string, state: boolean) => {
  if (state === true) {
    accountsForPatch.value.push({ number: id, is_connected: state })
  } else {
    accountsForPatch.value = accountsForPatch.value.filter((el) => el.number !== id)
  }
}
</script>

<template>
  <AForm
    ref="importFormRef"
    name="create-operation-categories-form"
    :label-col="{ span: 24 }"
    :wrapper-col="{ span: 24 }"
    autocomplete="off"
    :colon="false"
    scroll-to-first-error
    @submit.prevent="submitAction"
    @keydown.enter.prevent
  >
    <ATypographyParagraph :style="{ fontSize: '16px', marginBottom: '20px' }"
      >Выберите дату с которой вы хотите импортировать операции</ATypographyParagraph
    >
    <AFormItem
      label="Дата"
      name="bank_import_from"
      v-bind="validateInfos?.bank_import_from"
      :style="{ marginBottom: '32px' }"
    >
      <ADatePicker
        v-model="formState.bank_import_from"
        :style="{ width: '100%', height: '40px' }"
      />
    </AFormItem>
    <ATypographyParagraph :style="{ fontSize: '16px', marginBottom: '16px' }"
      >Выберите счета, которые хотите подключить:</ATypographyParagraph
    >
    <AFlex
      v-for="(account, id) in props.data"
      :key="id"
      justify="space-between"
      :style="{ marginBottom: '16px', fontSize: '16px' }"
    >
      {{ account?.name }} {{ account?.number }}
      <BankFormSwitch
        :id="account?.number"
        :state="account?.is_connected"
        :on-checked-change="connect"
      />
    </AFlex>
  </AForm>
</template>
