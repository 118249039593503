import type { SorterResult } from "ant-design-vue/es/table/interface"

import { defineStore } from "pinia"
import { computed, type MaybeRef, ref, watch } from "vue"
import { push } from "notivue"
import { get, set } from "@vueuse/core"
import { HttpStatusCode } from "axios"

import {
  type Counterparty,
  type EditCounterparty,
  UseCounterpartiesCreate,
  UseCounterpartiesDelete,
  UseCounterpartiesEdit,
  useCounterpartiesList,
  UseCounterpartiesListDelete,
  useGetCounterpartiesSorting,
  useSaveCounterpartiesSorting
} from "@/modules/counterparty"
import { useListBufferApi, useListingSort, usePagination } from "@/package/hooks"
import { PER_PAGE_DEFAULT, SortOrder } from "@/interfaces"
import { getOrderBySorter } from "@/package/ui-kit"

import { detailsGet } from "../api/details.get"

export const useCounterpartiesStore = defineStore("counterpartiesList", () => {
  const { pagination, paginationTotal, setPagination, decrementTotal } = usePagination()
  const listingBuffer = ref<Array<Counterparty>>([])
  const searchQuery = ref("")
  const searchQueryReadonly = computed(() => searchQuery.value)
  const { sortBy, sortOrder, sortUsed, setSort, resetSort } = useListingSort()

  const { executeListing, isListingLoading, data, listingApiError, listingResponseStatus } =
    useCounterpartiesList()
  const {
    bufferRO,
    bufferRewrite,
    updateBufferFn,
    setUpdateFn,
    bufferReplace,
    bufferConcat,
    bufferUnshift,
    bufferFilterOneConcat
  } = useListBufferApi(listingBuffer)

  const idList = computed(() => bufferRO.value.map(({ id }) => id))

  const isResourceEmpty = computed(() => get(listingResponseStatus) === HttpStatusCode.NoContent)
  const isLoadedFull = computed(() => {
    const total = get(pagination).total
    return (total !== null && total <= get(listingBuffer).length) || get(isResourceEmpty)
  })
  watch(listingApiError, (error) => {
    if (error && error.status !== 404) {
      push.error({ message: "Неизвестная ошибка, пожалуйста сообщите поддержке!" })
    }
  })
  watch(data, (listing) => {
    if (listing && listing.data.length > 0) {
      updateBufferFn(listing.data)
      setPagination(listing.pagination)
    } else {
      updateBufferFn([])
      setPagination({ ...get(pagination), total: 0 })
    }
  })

  const loadNextPart = async () => {
    setUpdateFn(bufferConcat)
    await executeListing({
      offset: listingBuffer.value.length,
      limit: PER_PAGE_DEFAULT,
      query: get(searchQuery) || undefined,
      order: get(sortOrder),
      orderBy: get(sortBy)
    })
  }

  const reloadBuffer = async () => {
    setUpdateFn(bufferRewrite)
    await executeListing({
      offset: 0,
      limit: get(listingBuffer, "length"),
      query: get(searchQuery) || undefined,
      order: get(sortOrder),
      orderBy: get(sortBy)
    })
  }
  const { createCounterparty, isCreating, createError, isCreateFinished } =
    UseCounterpartiesCreate()

  const updateBufferAfterCreate = async () => {
    let result = null

    setUpdateFn(bufferUnshift)
    const response = await executeListing({
      offset: 0,
      limit: 1,
      query: get(searchQuery) || undefined
    })
    result = response.data.value?.data[0]

    if (get(sortUsed)) {
      await reloadBuffer()
    }

    return result
  }

  const { executeDelete, isDeleting, counterpartyDeleteError } = UseCounterpartiesDelete()

  const deleteCounterparty = async (id: number) => {
    if (isLoadedFull.value) {
      await executeDelete({ id })
      bufferFilterOneConcat(id, [])
      decrementTotal()
    } else {
      await executeDelete({ id })
      setUpdateFn(bufferFilterOneConcat.bind(null, id))
      await executeListing({
        offset: listingBuffer.value.length,
        limit: 1,
        query: get(searchQuery) || undefined,
        order: get(sortOrder),
        orderBy: get(sortBy)
      })
    }
  }

  const { requestListDelete, isListDeleting, counterpartyDeleteListError } =
    UseCounterpartiesListDelete()

  const deleteByExcludedIdList = async (idList: MaybeRef<Array<number | string>>) => {
    await requestListDelete({ exclude_ids: get(idList), query: get(searchQuery) || undefined })
    await reloadBuffer()
  }
  const deleteBySelectedIdList = async (idList: MaybeRef<Array<number | string>>) => {
    await requestListDelete({ include_ids: get(idList) })
    await reloadBuffer()
  }

  const { editCounterparty, isEditing, editError, isEditingFinished } = UseCounterpartiesEdit()

  const updateBufferAfterEdit = async (data: EditCounterparty) => {
    const { id, name, phone, contact_person } = data
    const updatedId = listingBuffer.value.findIndex(({ id: buffId }) => id === buffId)
    if (updatedId !== -1) {
      bufferReplace(updatedId, {
        id,
        name: name ?? "",
        phone: phone ?? "",
        contact_person: contact_person ?? ""
      })
    }
  }

  const searchListing = async (query: MaybeRef<string>) => {
    set(searchQuery, get(query))
    setPagination()
    setUpdateFn(bufferRewrite)
    await executeListing({
      offset: get(pagination, "offset"),
      limit: get(pagination, "limit"),
      query: get(searchQuery) || undefined,
      order: get(sortOrder),
      orderBy: get(sortBy)
    })
  }

  const loadOne = async (id: string | number) => {
    const counterparty = await detailsGet(id)
    bufferConcat(counterparty)
  }

  const setSortOrder = async (sorter: SorterResult) => {
    const orderBy = getOrderBySorter(sorter)
    if (orderBy !== null && typeof sorter.field === "string") {
      setSort(sorter.field, orderBy)
    } else {
      resetSort()
    }
    setPagination()
    setUpdateFn(bufferRewrite)
    await executeListing({
      offset: get(pagination, "offset"),
      limit: get(pagination, "limit"),
      query: get(searchQuery) || undefined,
      order: get(sortOrder),
      orderBy: get(sortBy)
    })
  }

  const { getSorting, isSortingLoading } = useGetCounterpartiesSorting()

  const getCounterpartiesSorting = async () => {
    const response = await getSorting()
    if (response?.sortBy && typeof response?.sortOrder === "string") {
      setSort(response?.sortBy, response?.sortOrder as SortOrder)
    } else {
      resetSort()
    }
    setPagination()
    setUpdateFn(bufferRewrite)
    await executeListing({
      offset: get(pagination, "offset"),
      limit: get(pagination, "limit"),
      query: get(searchQuery) || undefined,
      order: get(sortOrder),
      orderBy: get(sortBy)
    })
  }

  const { executeSave } = useSaveCounterpartiesSorting()

  const saveCounterpartiesSorting = async () => {
    await executeSave({ sortBy: sortBy.value, sortOrder: sortOrder.value })
  }

  const $reset = (isSelectOptionsReset: boolean = true) => {
    if (isSelectOptionsReset) {
      set(listingBuffer, [])
      setPagination()
    }
    set(searchQuery, undefined)
    resetSort()
  }

  return {
    isResourceEmpty,
    searchQuery: searchQueryReadonly,
    listing: bufferRO,
    listingApiError,
    idList,
    pagination,
    paginationTotal,
    isLoadedFull,
    loadNextPart,
    isListingLoading,
    isCreateFinished,
    isCreating,
    createCounterparty,
    createError,
    updateBufferAfterCreate,
    isDeleting,
    deleteCounterparty,
    counterpartyDeleteError,
    counterpartyDeleteListError,
    isListDeleting,
    deleteByExcludedIdList,
    deleteBySelectedIdList,
    isEditingFinished,
    isEditing,
    editCounterparty,
    editError,
    updateBufferAfterEdit,
    searchListing,
    sortOrder,
    getCounterpartiesSorting,
    saveCounterpartiesSorting,
    isSortingLoading,
    sortUsed,
    loadOne,
    setSortOrder,
    $reset
  }
})
