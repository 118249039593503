import { Mask } from "maska"
import { computed, type Ref } from "vue"

import type { Profile } from "@/modules/profile"
import { viewValueOrDefault } from "@/package/ui-kit"

export const useProfileView = (profile: Ref<Profile | undefined>) => {
  const mask = new Mask({ mask: "+7 (###) ###-##-##" })
  return computed<Profile | undefined>(() => {
    let result
    if (profile.value) {
      const { phone, middle_name, ...dto } = profile.value
      result = {
        phone: mask.masked(phone),
        middle_name: viewValueOrDefault(middle_name),
        ...dto
      }
    }
    return result
  })
}
