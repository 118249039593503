<script setup lang="ts">
import type { OperationFileData } from "../interface"

import { watch, computed, h, type PropType } from "vue"
import { get, set } from "@vueuse/core"
import { DeleteOutlined, InboxOutlined, PaperClipOutlined } from "@ant-design/icons-vue"
import { push } from "notivue"

import { useFileDownload } from "@/modules/operation"
import { DEFAULT_REQUEST_ERROR_MESSAGE } from "@/interfaces"

import { useFileUpload } from "../hooks/use-file-upload"

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false
  }
})
const files = defineModel({
  type: Array as PropType<Array<OperationFileData>>,
  default: []
})
const { executeDownload, downloadFileError } = useFileDownload()

watch(downloadFileError, (err) => {
  if (err?.message) {
    push.error({ message: err.message })
  }
})

const { upload, uploadError } = useFileUpload()
watch(uploadError, (err) => {
  if (err) {
    push.error({ message: err.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})
const uploadAction = async (opts: any) => {
  const file = await upload(opts)
  if (file !== null) {
    const resFiles: Array<OperationFileData> = [...get(files), file]
    set(files, resFiles)
  }
}
const remove = (removedFileId: number) => {
  const filteredValues = get(files).filter(({ id }) => id !== removedFileId)
  files.value = filteredValues ?? []
}
const fileData = computed(() => (props.disabled ? [] : get(files)))
</script>

<template>
  <AUploadDragger
    :disabled="disabled"
    :files="fileData"
    :file-list="fileData"
    :class="files?.length! >= 10 ? 'upload--hidden' : 'upload--visible'"
    name="file"
    :multiple="false"
    :custom-request="uploadAction"
  >
    <p class="ant-upload-drag-icon upload__drag-icon">
      <InboxOutlined style="width: 32px; height: 32px" />
    </p>
    <p class="ant-upload-text" style="letter-spacing: -0.25px">
      Нажмите или перетащите файл для загрузки (Не более 10 файлов)
    </p>
    <div style="padding: 0 25px">
      <AText type="secondary"
        >Поддерживаемые форматы: pdf, doc, docx, xls, xlsx, jpeg, png, zip, rar, txt, csv,
        xml</AText
      >
    </div>
    <template #itemRender="{ file }">
      <ASpace style="width: 100%; display: flex; justify-content: space-between">
        <ASpace>
          <PaperClipOutlined />
          <AButton type="link" @click="() => executeDownload(file.id, file.name)">{{
            file.name
          }}</AButton>
        </ASpace>
        <AButton :icon="h(DeleteOutlined)" type="text" @click="remove(file.id)" />
      </ASpace>
    </template>
  </AUploadDragger>
</template>

<style scoped>
.upload--visible :deep(.ant-upload-drag) {
  padding: 16px 0;
}

.upload--visible :deep(.ant-upload-drag .ant-upload) {
  padding: 0;
}

.upload--hidden :deep(.ant-upload-drag) {
  display: none;
}

.upload__drag-icon {
  margin-bottom: 0 !important;
}
</style>
