import type { FormValidator } from "./types"

import plural from "plural-ru"

export const createMaxValidator: (length: number, message?: string) => FormValidator = function (
  length,
  message
) {
  const errorMessage = message
    ? message
    : `Минимум ${length} ${plural(length, "символ", "символа", "символов")}`
  return ({ required }, value) =>
    new Promise((resolve, reject) => {
      if (!required && !value) {
        resolve(undefined)
      }
      if (typeof value === "string" && value.length <= length) {
        resolve(undefined)
      } else {
        reject(errorMessage)
      }
    })
}
