<script setup lang="ts">
import { computed, type PropType } from "vue"

import { viewValueOrDefault } from "@/package/ui-kit"

import {
  type UserDetail,
  UserPasswordStatus,
  UserPasswordStatusDict,
  UserEmailStatus,
  UserEmailStatusDict,
  UserStatus,
  UserStatusDict
} from "../interface"
import { useUserRoles } from "../hooks/use-user-roles"

const props = defineProps({
  user: {
    type: [Object, undefined] as PropType<UserDetail | undefined>,
    default: () => undefined
  }
})

const { userRolesResponse } = useUserRoles()
const role = computed(() => {
  return userRolesResponse.value?.find((roleOption) => roleOption.id === props.user?.role)?.name
})
</script>

<template>
  <ADescriptions layout="vertical" :column="24">
    <ADescriptionsItem :span="24" label="Фамилия">{{
      viewValueOrDefault(user?.last_name)
    }}</ADescriptionsItem>
    <ADescriptionsItem :span="24" label="Имя">{{
      viewValueOrDefault(user?.first_name)
    }}</ADescriptionsItem>
    <ADescriptionsItem :span="24" label="Отчество">{{
      viewValueOrDefault(user?.middle_name)
    }}</ADescriptionsItem>
    <ADescriptionsItem :span="24" label="E-mail">{{
      viewValueOrDefault(user?.email)
    }}</ADescriptionsItem>
    <ADescriptionsItem :span="24" label="Роль">{{ viewValueOrDefault(role) }}</ADescriptionsItem>
    <ADescriptionsItem :span="24" label="Статус">
      <template v-if="user">
        <ATag :color="user.status === UserStatus.active ? 'success' : 'error'">{{
          viewValueOrDefault(UserStatusDict[user.status])
        }}</ATag>
      </template>
      <template v-else>{{ viewValueOrDefault(undefined) }}</template>
    </ADescriptionsItem>
    <ADescriptionsItem :span="24" label="Статус подтверждения почты">
      <template v-if="user">
        <ATag :color="user.status_email === UserEmailStatus.active ? 'success' : 'error'">{{
          viewValueOrDefault(UserEmailStatusDict[user.status_email])
        }}</ATag>
      </template>
      <template v-else>{{ viewValueOrDefault(undefined) }}</template>
    </ADescriptionsItem>
    <ADescriptionsItem :span="24" label="Статус блокировки пароля">
      <template v-if="user">
        <ATag :color="user.status_pass === UserPasswordStatus.active ? 'success' : 'error'">{{
          viewValueOrDefault(UserPasswordStatusDict[user.status_pass])
        }}</ATag>
      </template>
      <template v-else>{{ viewValueOrDefault(undefined) }}</template>
    </ADescriptionsItem>
  </ADescriptions>
</template>
