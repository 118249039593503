<script setup lang="ts">
import type { Form } from "ant-design-vue"

import { computed, onMounted, onUnmounted, type PropType, ref, toRefs, watch } from "vue"
import { push } from "notivue"
import { storeToRefs } from "pinia"

import { useOperationCategoriesStore } from "@/modules/operation-category"
import { usePopupContainerGetter } from "@/package/ui-kit"
import { useForm } from "@/package/hooks"
import { DEFAULT_REQUEST_ERROR_MESSAGE } from "@/interfaces"
import { isValidateError } from "@/package/util"

import CategoryTypeSelect from "../CategoryTypeSelect.vue"
import OperationCategorySelect from "../OperationCategorySelect/OperationCategorySelect.vue"

import { useFormState } from "./use-form-state"
import { useRuleset } from "./use-ruleset"

const props = defineProps({
  getContainer: {
    type: [Function, undefined] as PropType<() => HTMLElement | null>,
    default: undefined
  },

  actionType: {
    type: String,
    default: undefined
  }
})

const emit = defineEmits(["success"])

const { getContainer } = toRefs(props)

const { formState, requestData } = useFormState()
const ruleset = useRuleset()
const { validate, validateInfos, isValidating } = useForm(formState, ruleset)
const operationsStore = useOperationCategoriesStore()
const { createError, isCreating, isCreateFinished } = storeToRefs(operationsStore)

onMounted(() => {
  formState.action_type = props.actionType ?? operationsStore.actionType
})

watch([createError, isCreateFinished], ([err, finishedStatus]) => {
  if (err && finishedStatus) {
    isCreateFinished.value = false
    push.error({ message: err.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})

const formRef = ref<typeof Form | null>(null)

const submitAction = () =>
  validate()
    .then(() => operationsStore.createOperationCategories(requestData.value))
    .then((id: number) => {
      push.success({
        message: "Статья создана"
      })
      emit("success", id)
      operationsStore.updateBufferAfterCreate(id)
    })
    .catch((err) => {
      if (isValidateError(err)) {
        formRef.value?.scrollToField(err.errorFields[0].name, {
          block: "start",
          boundary: (parent: HTMLElement) => {
            parent.focus()
            return parent.classList.contains("ant-row")
          }
        })
      }
    })

defineExpose({
  submitAction,
  isCreating: computed(() => isCreating.value || isCreateFinished.value || isValidating.value)
})
const popupContainerGetter = usePopupContainerGetter(getContainer?.value)

onUnmounted(() => {
  isCreateFinished.value = false
})
</script>

<template>
  <AForm
    ref="formRef"
    name="create-operation-categories-form"
    :label-col="{ span: 24 }"
    :wrapper-col="{ span: 24 }"
    autocomplete="off"
    :colon="false"
    :style="{ marginTop: '12px' }"
    scroll-to-first-error
    @submit.prevent="submitAction"
    @keydown.enter.prevent
  >
    <AFormItem
      label="Название"
      name="name"
      v-bind="validateInfos?.name"
      :style="{ marginTop: '16px' }"
    >
      <AInput v-model="formState.name" size="large" placeholder="Введите название" />
    </AFormItem>
    <AFormItem label="Родительская статья" name="parent_id" v-bind="validateInfos?.parent_id">
      <OperationCategorySelect
        v-model="formState.parent_id"
        :get-popup-container="popupContainerGetter"
        :action-type="actionType || operationsStore.actionType"
        hide-link
        size="large"
      />
    </AFormItem>
    <AFormItem label="Тип" name="type" v-bind="validateInfos?.type">
      <CategoryTypeSelect
        v-model:value="formState.type"
        :get-popup-container="popupContainerGetter"
        size="large"
      />
    </AFormItem>
    <AFormItem label="Комментарий" name="comment" v-bind="validateInfos?.comment">
      <ATextarea v-model:value="formState.comment" placeholder="Введите комментарий" :rows="3" />
    </AFormItem>
  </AForm>
</template>
