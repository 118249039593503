import type { RegisterLegalEntity } from "@/modules/legal-entity"

export enum RegistrantType {
  PhysicalEntity = "PhysicalEntity",
  LegalEntity = "LegalEntity"
}

export interface RegisterForm
  extends Omit<RegisterLegalEntity, "phone" | "monthly_turnover_rate" | "okved_id"> {
  monthly_turnover_rate?: number
  okved_id?: number
  accept: boolean
  phone: string
  promocode?: string
}
