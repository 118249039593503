import type { SorterResult } from "ant-design-vue/es/table/interface"

import { defineStore } from "pinia"
import { computed, type MaybeRef, ref, watch } from "vue"
import { push } from "notivue"
import { CanceledError, HttpStatusCode } from "axios"
import { get, set } from "@vueuse/core"

import type { LegalEntitySummary } from "@/modules/legal-entity"
import { useListBufferApi, useListingSort, usePagination } from "@/package/hooks"
import { getOrderBySorter } from "@/package/ui-kit"
import { SortOrder } from "@/interfaces"

import { legalEntityOneGet } from "../api/legal-entity-one.get"
import { useLegalEntityListing } from "../hooks/use-legal-entity-listing"
import { useLegalEntityCreate } from "../hooks/use-legal-entity-create"
import { useLegalEntityDelete } from "../hooks/use-legal-entity-delete"
import { useLegalEntityListDelete } from "../hooks/use-legal-entity-list-delete"
import { useLegalEntityEdit } from "../hooks/use-legal-entity-edit"
import { useGetLegalEntitySorting } from "../hooks/use-get-legal-entity-sorting"
import { useSaveLegalEntitySorting } from "../hooks/use-save-legal-entity-sorting"

export const useLegalEntityStore = defineStore("LegalEntityStore", () => {
  const buffer = ref<LegalEntitySummary[]>(new Array<LegalEntitySummary>())
  const { pagination, paginationTotal, currentOffset, setPagination, decrementTotal } =
    usePagination()
  const { sortOrder, sortBy, sortUsed, resetSort, setSort } = useListingSort()
  const searchQuery = ref<string>()

  const idList = computed(() => buffer.value.map(({ id }) => id))

  const {
    bufferRO,
    updateBufferFn,
    setUpdateFn,
    bufferReplaceByFirst,
    bufferConcat,
    bufferUnshift,
    bufferRewrite,
    bufferFilterOneConcat
  } = useListBufferApi(buffer)

  const { data, listingLoad, isListingLoading, listingResponseStatus, listingApiError } =
    useLegalEntityListing()

  const isResourceEmpty = computed(() => get(listingResponseStatus) === HttpStatusCode.NoContent)
  const isLoadedFull = computed(() => {
    const total = get(pagination).total
    return (total !== null && total <= get(buffer).length) || get(isResourceEmpty)
  })

  watch(data, (listing) => {
    if (listing && listing.data.length > 0) {
      updateBufferFn(listing.data)
      setPagination(listing.pagination)
    } else {
      updateBufferFn([])
      setPagination({ ...get(pagination), total: 0 })
    }
  })

  watch(listingApiError, (error) => {
    if (error && error.status !== 404) {
      push.error({ message: "Неизвестная ошибка, пожалуйста сообщите поддержке!" })
    }
  })

  const loadBufferNextPage = async () => {
    try {
      setUpdateFn(bufferConcat)
      await listingLoad({
        limit: get(pagination).limit,
        offset: get(buffer).length,
        order: get(sortOrder),
        orderBy: get(sortBy)
      })
    } catch (e) {
      if (!(e instanceof CanceledError)) {
        throw e
      }
    }
  }
  const reloadBuffer = async () => {
    setUpdateFn(bufferRewrite)
    await listingLoad({
      offset: 0,
      limit: get(buffer, "length"),
      order: get(sortOrder),
      orderBy: get(sortBy)
    })
  }

  const { isLegalEntityDeleting, legalEntityDeleteError, requestLegalEntityDelete } =
    useLegalEntityDelete()
  const deleteOne = async (id: MaybeRef<number | string>) => {
    const targetId = get(id)
    if (isLoadedFull.value) {
      await requestLegalEntityDelete(targetId)
      bufferFilterOneConcat(targetId as number, [])
      decrementTotal()
    } else {
      await requestLegalEntityDelete(targetId)
      setUpdateFn(bufferFilterOneConcat.bind(null, Number(targetId)))
      await listingLoad({
        offset: get(buffer, "length") - 1,
        limit: 1,
        order: get(sortOrder),
        orderBy: get(sortBy)
      })
    }
  }

  const { isLegalEntityListDeleting, legalEntityListDeleteError, requestLegalEntityListDelete } =
    useLegalEntityListDelete()
  const deleteByExcludedIdList = async (id_list: MaybeRef<Array<number | string>>) => {
    const idList = get(id_list)
    await requestLegalEntityListDelete({ exclude_ids: idList })
    await reloadBuffer()
  }
  const deleteBySelectedIdList = async (id_list: MaybeRef<Array<number | string>>) => {
    const idList = get(id_list)
    await requestLegalEntityListDelete({ include_ids: idList })
    await reloadBuffer()
  }

  const { createLegalEntity, isLegalEntityCreating, legalEntityCreateError, isCreateFinished } =
    useLegalEntityCreate()

  const updateBufferAfterCreate = async () => {
    let result = null
    setUpdateFn(bufferUnshift)
    const response = await listingLoad({ offset: 0, limit: 1 })
    result = response.data.value?.data[0].id

    if (get(sortUsed)) {
      await reloadBuffer()
    }

    return result
  }

  const { editLegalEntity, isLegalEntityEditing, legalEntityEditError, isEditingFinished } =
    useLegalEntityEdit()

  const updateBufferAfterEdit = async (id: string | number) => {
    const updatedId = Number(id)
    const updatedIndex = buffer.value.findIndex(({ id }) => updatedId === id)
    setUpdateFn(bufferReplaceByFirst.bind(null, updatedIndex))
    await listingLoad({
      offset: updatedIndex,
      limit: 1,
      order: get(sortOrder),
      orderBy: get(sortBy)
    })
  }

  const loadOne = async (id: string | number) => {
    const resId = +id
    const { itn, organization_name, bic, account_number } = await legalEntityOneGet(resId)
    bufferConcat({ id: resId, itn, organization_name, bic, account_number })
  }

  const setSortOrder = async (sorter: SorterResult) => {
    const orderBy = getOrderBySorter(sorter)
    if (orderBy !== null && typeof sorter.field === "string") {
      setSort(sorter.field, orderBy)
    } else {
      resetSort()
    }
    setPagination()
    setUpdateFn(bufferRewrite)
    await listingLoad({
      offset: get(pagination, "offset"),
      limit: get(pagination, "limit"),
      order: get(sortOrder),
      orderBy: get(sortBy)
    })
  }

  const searchListing = async (query: string) => {
    searchQuery.value = query
    setPagination()
    setUpdateFn(bufferRewrite)
    await listingLoad({
      offset: get(pagination, "offset"),
      limit: get(pagination, "limit"),
      query: get(searchQuery) || undefined,
      order: get(sortOrder),
      orderBy: get(sortBy)
    })
  }

  const { getSorting, isSortingLoading } = useGetLegalEntitySorting()

  const getLegalEntitySorting = async () => {
    const response = await getSorting()
    if (response?.sortBy && typeof response?.sortOrder === "string") {
      setSort(response?.sortBy, response?.sortOrder as SortOrder)
    } else {
      resetSort()
    }
    setPagination()
    setUpdateFn(bufferRewrite)
    await listingLoad({
      offset: get(pagination, "offset"),
      limit: get(pagination, "limit"),
      order: get(sortOrder),
      orderBy: get(sortBy)
    })
  }

  const { executeSave } = useSaveLegalEntitySorting()

  const saveLegalEntitySorting = async () => {
    await executeSave({ sortBy: sortBy.value, sortOrder: sortOrder.value })
  }

  const $reset = () => {
    setPagination()
    set(buffer, [])
    resetSort()
  }

  return {
    isResourceEmpty,
    idList,
    listing: bufferRO,
    listingApiError,
    isListingLoading,
    isLoadedFull,
    currentOffset,
    paginationTotal,

    loadBufferNextPage,
    loadOne,

    deleteOne,
    isLegalEntityDeleting,
    legalEntityDeleteError,

    deleteByExcludedIdList,
    deleteBySelectedIdList,
    isLegalEntityListDeleting,
    legalEntityListDeleteError,
    searchListing,
    createLegalEntity,
    isLegalEntityCreating,
    createError: legalEntityCreateError,
    isCreateFinished,
    updateBufferAfterCreate,
    editLegalEntity,
    isLegalEntityEditing,
    editError: legalEntityEditError,
    isEditingFinished,
    sortOrder,
    isSortingLoading,
    getLegalEntitySorting,
    saveLegalEntitySorting,
    updateBufferAfterEdit,
    setSortOrder,
    $reset
  }
})
