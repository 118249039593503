<script setup lang="ts">
import { computed, h, onMounted, onUnmounted, type PropType, ref, watch } from "vue"
import { useToggle } from "@vueuse/core"
import { debounce, throttle } from "lodash-es"
import { TransitionFade } from "@morev/vue-transitions"
import { storeToRefs } from "pinia"
import { push } from "notivue"

import {
  type LegalEntityCreate,
  LegalEntityForm,
  useLegalEntityStore
} from "@/modules/legal-entity"
import { AFakeOption, VNodeRender } from "@/package/ui-kit"
import { ApiBadReqResponse, ApiNotFoundResponse } from "@/package/api-client"
import { DETAIL_REQUEST_ERROR_MESSAGE } from "@/modules/operation"
import { useBankAccountStore } from "@/modules/bank-account"

defineProps({
  hideLink: {
    type: Boolean,
    default: false
  }
})

const [selectedId, modifiers] = defineModel({
  type: [Number, null] as PropType<number | null | undefined>,
  get(v) {
    if (store.isListingLoading && store.listing.length === 0) {
      return -1
    }
    return v
  },
  set(v) {
    let result = v
    if (modifiers["null-on-empty"]) {
      result = v === undefined ? null : v
    }
    return result
  }
})

const [isSelectOpen, toggleSelectOpen] = useToggle(false)

const [isFormOpen, toggleFormOpen] = useToggle(false)
const formRef = ref<InstanceType<typeof LegalEntityForm> | null>(null)

const createLegalEntity = () => {
  formRef?.value?.submitAction()
}

const cancelCreateForm = () => {
  toggleFormOpen(false)
}

const getContainer = (): HTMLElement | null =>
  document.querySelector(
    ".legal-entity-drawer .ant-drawer-body div[data-overlayscrollbars-contents]"
  )

const openForm = () => {
  toggleSelectOpen(false)
  toggleFormOpen(true)
}

const [isLoading, toggleLoading] = useToggle(false)

const store = useLegalEntityStore()
const bankStore = useBankAccountStore()
const { isLegalEntityCreating, isCreateFinished, listingApiError } = storeToRefs(store)

const options = computed(() =>
  store.isListingLoading && store.listing.length === 0
    ? [{ label: "Загрузка...", value: -1 }]
    : store.listing.map((item) => ({ label: item.organization_name, value: item.id }))
)
const LoadingOption = () =>
  h(AFakeOption, { disabled: true }, () =>
    store.paginationTotal
      ? `Список Юр лиц загружается, ${store.currentOffset} из ${store.paginationTotal}`
      : "Список Юр лиц загружается"
  )

watch(
  listingApiError,
  async (err) => {
    if (err) {
      push.error({ message: err.message ?? DETAIL_REQUEST_ERROR_MESSAGE })
    }
  },
  { immediate: true }
)

onMounted(async () => {
  if (!store.listing.length) {
    await store.loadBufferNextPage()
  }
})

watch(
  selectedId,
  async (id) => {
    if (id && id !== -1 && !store.idList.includes(id) && store.listing.length) {
      try {
        await store.loadOne(id)
      } catch (e) {
        if (e instanceof ApiNotFoundResponse) {
          selectedId.value = undefined
        } else {
          push.error({ message: "Неудалось загрузить ЮР. лицо" })
        }
      }
    }
  },
  { immediate: true }
)

const scrollAction = throttle(async (e: any) => {
  if (e?.target?.scrollTop && e?.target?.scrollHeight && !isLoading.value && !store.isLoadedFull) {
    const scrolled = e.target.scrollHeight - e.target.scrollTop
    const scrollHalf = e.target.scrollHeight / 4
    if (scrolled <= scrollHalf) {
      toggleLoading(true)
      await store.loadBufferNextPage()
      toggleLoading()
    }
  }
}, 1000)
const searchAction = debounce(store.searchListing, 1000)
const cancelAction = () => {
  scrollAction.flush()
  searchAction.flush()
}

const resetSearch = (id?: number) => {
  if (!id) {
    store.searchListing("")
  }
}

const submitAction = async (requestData: LegalEntityCreate) => {
  try {
    const newId = await store.createLegalEntity(requestData)
    push.success({
      message: "Информация сохранена"
    })
    selectedId.value = newId
    toggleFormOpen(false)
    await store.updateBufferAfterCreate()
    await bankStore.updateBufferAfterCreate()
  } catch (e) {
    if (e instanceof ApiBadReqResponse || e instanceof ApiNotFoundResponse) {
      push.error({
        message: e.message
      })
    } else {
      push.error({
        message: "Неизвестная ошибка, пожалуйста повторите позже или обратитесь в поддержку."
      })
    }
    isCreateFinished.value = false
  }
}

onUnmounted(store.$reset)
</script>

<template>
  <ASelect
    v-model:value="selectedId"
    v-model:open="isSelectOpen"
    size="large"
    :options="options"
    :loading="isLoading"
    :default-active-first-option="false"
    show-search
    allow-clear
    virtual
    :filter-option="false"
    @search="searchAction"
    @popupScroll="scrollAction"
    @blur="cancelAction"
    @dropdownVisibleChange="toggleSelectOpen"
    @focus="() => toggleSelectOpen(true)"
    @change="resetSearch"
  >
    <template #dropdownRender="{ menuNode }">
      <APageDrawer
        v-if="isFormOpen"
        v-model:open="isFormOpen"
        title="Создание юридического лица"
        class-name="legal-entity-drawer"
      >
        <LegalEntityForm ref="formRef" :get-container="getContainer" @submit="submitAction" />
        <template #footer>
          <ARow :gutter="[8, 8]">
            <ACol :sm="24" :md="12" flex="1 1 50%">
              <AButton size="large" block @click="cancelCreateForm">Отмена</AButton>
            </ACol>
            <ACol :sm="24" :md="12" flex="1 1 50%">
              <AButton
                size="large"
                block
                type="primary"
                :loading="isLegalEntityCreating"
                @click="createLegalEntity"
                >Создать</AButton
              >
            </ACol>
          </ARow>
        </template>
      </APageDrawer>
      <AButton v-if="!hideLink" type="link" @click="openForm">Создать новое юр.лицо</AButton>
      <VNodeRender :node="menuNode" />
      <TransitionFade :easing="{ enter: '1s', leave: '1s' }">
        <LoadingOption v-if="isLoading" />
      </TransitionFade>
    </template>
  </ASelect>
</template>
