<script setup lang="ts">
import { computed, h, onMounted, ref, watch } from "vue"
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  DownloadOutlined,
  BankOutlined
} from "@ant-design/icons-vue"
import { push } from "notivue"
import { TransitionFade } from "@morev/vue-transitions"
import { noop, watchThrottled } from "@vueuse/core"
import { theme } from "ant-design-vue"
import { storeToRefs } from "pinia"

import {
  DeleteConfirm,
  useAdoptable,
  useSmallDesktopResolution,
  useTableResolution
} from "@/package/ui-kit"
import router, {
  FinanceOperationsBanksRouteName,
  FinanceOperationsCreateRouteName,
  FinanceOperationsDetailRouteName,
  FinanceOperationsEditListRouteName,
  FinanceOperationsEditRouteName,
  FinanceOperationsImportRouteName,
  FinanceOperationsRestoreRouteName
} from "@/router"
import { OperationListing, useOperationStore } from "@/modules/operation"
import { ApiNotFoundResponse } from "@/package/api-client"
import { useImportBus, useOperationsCreateBus } from "@/modules/centrifugo"
import { DEFAULT_REQUEST_ERROR_MESSAGE } from "@/interfaces"
import { formatThousands } from "@/package/util"

import OperationSeparateFilters from "../../../modules/operation/components/OperationFiltersForm/ui/OperationSeparateFilters.vue"

const isAdoptable = useAdoptable()
const { token } = theme.useToken()
const store = useOperationStore()
const isSmallDesktop = useSmallDesktopResolution()
const isTableResolution = useTableResolution()

const { operationDeleteError, operationCopyError } = storeToRefs(store)
const operationsCreateBus = useOperationsCreateBus()
const importBus = useImportBus()
operationsCreateBus.on(store.busEventsHandler.bind(null, "Повторения операции созданы"))
importBus.on(store.busEventsHandler.bind(null, "Импорт завершен"))

const searchQuery = ref("")
watchThrottled(
  searchQuery,
  (value) => {
    store.searchListing(value)
  },
  { throttle: 1000, leading: false }
)

const listingRef = ref<InstanceType<typeof OperationListing> | null>(null)
const allChecked = computed({
  get: () => listingRef.value?.isAllSelected,
  set: (value) => {
    listingRef.value?.toggleAllSelected(value)
  }
})
const summary = computed(() => {
  let result = null
  if (
    listingRef.value &&
    listingRef.value.selectedCount > 0 &&
    !(store.listingApiError?.status === ApiNotFoundResponse.STATUS)
  ) {
    result = `Выбрано ${listingRef.value.selectedCount} из ${store.paginationTotal} на сумму ${formatThousands(listingRef.value?.selectedOperationsSum)}`
  }
  return result
})

const openImportPage = () => {
  router.push({ name: FinanceOperationsImportRouteName })
}
const openCreatePage = () => router.push({ name: FinanceOperationsCreateRouteName })
const openDetailPage = (id: number | string) =>
  router.push({ name: FinanceOperationsDetailRouteName, params: { id } })
const openEditPage = (id: number | string) => {
  router.push({ name: FinanceOperationsEditRouteName, params: { id } })
}
const openBanksPage = () => {
  router.push({ name: FinanceOperationsBanksRouteName })
}
const openRestorePage = () => router.push({ name: FinanceOperationsRestoreRouteName })

const openListEditPage = () => {
  router.push({
    name: FinanceOperationsEditListRouteName,
    query: {
      idList: listingRef.value?.currentModeKeys,
      exclude: Number(listingRef.value?.excludeKeysMode)
    }
  })
}

watch(operationDeleteError, (error) => {
  if (error) {
    push.error({ message: error.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})

watch(operationCopyError, (error) => {
  if (error) {
    push.error({ message: error.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})

const deleteAction = () => {
  DeleteConfirm({
    content: "Вы действительно хотите безвозвратно удалить выбранные записи?",
    onOk: () => {
      if (listingRef.value !== null) {
        const keys = listingRef.value.currentModeKeys
        let promise = Promise.resolve()
        if (listingRef.value) {
          if (listingRef.value.excludeKeysMode) {
            promise = store.deleteByExcludedIdList(keys)
          } else {
            promise = store.deleteBySelectedIdList(keys)
          }
        }
        return promise
          .then(() => {
            push.success({ message: "Удалено" })
            listingRef.value?.unselectAllAction()
          })
          .catch(noop)
      }
    }
  })
}

onMounted(async () => {
  await store.getSavedFilters()
})
</script>

<template>
  <ARow
    :gutter="[16, 0]"
    justify="space-between"
    :style="{ marginTop: '32px', gap: isSmallDesktop ? '12px' : 0 }"
  >
    <ACol :xs="isAdoptable ? 0 : undefined">
      <AFlex :gap="16">
        <template v-if="isAdoptable">
          <ACheckbox v-model:checked="allChecked">Выбрать все</ACheckbox>
          <ATypographyText v-if="summary !== null" :content="summary" ellipsis />
        </template>
        <template v-else>
          <AButton default-styling size="large" type="primary" @click="openCreatePage">
            Создать
          </AButton>
          <AButton size="large" type="default" :icon="h(DownloadOutlined)" @click="openImportPage">
            Импортировать
          </AButton>
          <AButton size="large" type="default" :icon="h(BankOutlined)" @click="openBanksPage"
            >Банки</AButton
          >
          <AButton size="large" type="default" :icon="h(DeleteOutlined)" @click="openRestorePage"
            >Корзина</AButton
          >
        </template>
      </AFlex>
    </ACol>
    <ACol :xs="24" :md="12" :lg="8">
      <AFlex vertical :gap="16">
        <AFlex :gap="16">
          <AInputSearch
            v-model:value="searchQuery"
            :style="{ minWidth: isTableResolution ? '340px' : 'auto' }"
            size="large"
            placeholder="Сумма, статья, счет"
            :loading="searchQuery.length > 0 && store.isListingLoading"
            @search="store.searchListing"
          />
        </AFlex>
        <AFlex v-if="isAdoptable" :gap="16" wrap="wrap">
          <AButton
            size="large"
            type="default"
            :icon="h(DownloadOutlined)"
            :style="{ flexGrow: 1, flexBasis: 'calc(50% - 16px)' }"
            @click="openImportPage"
          >
            Импортировать
          </AButton>
          <AButton
            v-if="listingRef && listingRef.selectedCount > 1"
            :icon="h(EditOutlined)"
            type="default"
            size="large"
            :style="{ flexGrow: 1, flexBasis: 'calc(50% - 16px)' }"
            @click="openListEditPage"
          >
            Изменить
          </AButton>
        </AFlex>
        <AFlex v-if="isAdoptable" :gap="16" wrap="wrap">
          <AButton
            size="large"
            type="default"
            :icon="h(BankOutlined)"
            :style="{ flexGrow: 1, flexBasis: 'calc(50% - 16px)' }"
            @click="openBanksPage"
            >Банки</AButton
          >
        </AFlex>
      </AFlex>
    </ACol>
  </ARow>

  <div
    :style="{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: isSmallDesktop ? 'flex-start' : 'center',
      paddingTop: '10px',
      flexDirection: isSmallDesktop ? 'column' : 'row'
    }"
  >
    <OperationSeparateFilters />

    <ARow
      :gutter="[16, 0]"
      :style="{ marginTop: isAdoptable ? '32px' : '16px', justifyContent: 'space-between' }"
    >
      <ACol
        v-if="listingRef && listingRef.selectedCount > 0"
        :xs="0"
        :md="24"
        :style="{ minHeight: '32px' }"
      >
        <AFlex justify="space-between">
          <TransitionFade>
            <ASpace v-if="summary !== null" :size="16">
              <ATypographyText :content="summary" ellipsis />

              <AButton
                v-if="listingRef && listingRef.selectedCount > 1"
                :icon="h(EditOutlined)"
                type="text"
                :style="{ color: token.colorPrimary, padding: 0 }"
                @click="openListEditPage"
              >
                Изменить
              </AButton>

              <AButton
                type="text"
                danger
                :icon="h(DeleteOutlined)"
                :style="{ padding: 0 }"
                @click="deleteAction"
              >
                Удалить
              </AButton>
            </ASpace>
          </TransitionFade>
        </AFlex>
      </ACol>
      <ACol :xs="24" :md="0">
        <AFlex :gap="16" justify="space-between">
          <ACheckbox
            v-model:checked="allChecked"
            :indeterminate="listingRef && listingRef.selectedCount > 1 && !allChecked"
          >
            Выбрать все
          </ACheckbox>

          <div v-if="summary !== null">{{ summary }}</div>
        </AFlex>
      </ACol>
    </ARow>
  </div>

  <OperationListing
    ref="listingRef"
    :style="{ marginTop: '16px', flexGrow: 1 }"
    @openCreate="openCreatePage"
    @openDetail="openDetailPage"
    @openEdit="openEditPage"
  />

  <RouterView />

  <AListingControlPanel v-if="isAdoptable">
    <AButton size="large" :icon="h(PlusOutlined)" type="primary" @click="openCreatePage" />
    <AButton
      v-if="listingRef && listingRef.selectedCount"
      type="default"
      size="large"
      :icon="h(DeleteOutlined)"
      danger
      @click="deleteAction"
    >
      Удалить
    </AButton>
  </AListingControlPanel>
</template>

<style lang="scss">
.close-icon svg {
  width: 10px;
  height: 10px;
  position: absolute;
  top: -13px;
  right: -5px;
}
.close-button {
  margin-left: auto;
  border-radius: 50px !important;
  border: 2px solid black;
  position: absolute;
  width: 20px !important;
  height: 20px !important;
  z-index: 1000;
  right: -8px;
  top: -5px;
}
</style>
