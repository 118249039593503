<script setup lang="ts">
import { h } from "vue"
import { EditOutlined } from "@ant-design/icons-vue"

import { injectProfile, injectProfileLoading } from "@/modules/profile"

import { useProfileView } from "./use-profile-view"

defineEmits(["editProfile"])

const profile = injectProfile()
const isLoading = injectProfileLoading()
const profileView = useProfileView(profile)
defineOptions({
  inheritAttrs: true
})
</script>

<template>
  <ASpin :spinning="isLoading">
    <ADescriptions
      title="Личная информация"
      class="bottom-border top-border"
      layout="vertical"
      :style="{ padding: '8px 0 32px' }"
      v-bind="$attrs"
    >
      <template #extra>
        <AButton type="primary" :icon="h(EditOutlined)" @click="() => $emit('editProfile')" />
      </template>
      <ADescriptionsItem label="Фамилия">{{ profileView?.last_name }}</ADescriptionsItem>
      <ADescriptionsItem label="Имя">{{ profileView?.first_name }}</ADescriptionsItem>
      <ADescriptionsItem label="Отчество">{{ profileView?.middle_name }}</ADescriptionsItem>
      <ADescriptionsItem label="E-mail">{{ profileView?.email }}</ADescriptionsItem>
      <ADescriptionsItem label="Номер телефона">{{ profileView?.phone }}</ADescriptionsItem>
    </ADescriptions>
  </ASpin>
</template>
