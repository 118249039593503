<script setup lang="ts">
import { onMounted, ref } from "vue"

import { type UserFilters, useUserStore } from "@/modules/admin"

defineProps({
  showFilterPanel: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(["submit", "cancel"])

const store = useUserStore()

const formState = ref<Partial<UserFilters>>({})

onMounted(() => {
  formState.value.first_name = store.filters.first_name
  formState.value.last_name = store.filters.last_name
  formState.value.middle_name = store.filters.middle_name
  formState.value.email = store.filters.email
})
</script>

<template>
  <APageDrawer :open="showFilterPanel" title="Фильтры отчетов" @close="emit('cancel')">
    <AForm
      name="report-filters-form"
      :label-col="{ span: 24 }"
      :wrapper-col="{ span: 24 }"
      autocomplete="off"
      :colon="false"
      scroll-to-first-error
    >
      <AFormItem label="E-mail" name="email">
        <AInput
          v-model.null-on-empty="formState.email"
          type="email"
          placeholder="Укажите e-mail"
          size="large"
        />
      </AFormItem>

      <AFormItem label="Имя" name="name">
        <AInput
          v-model.null-on-empty="formState.first_name"
          type="email"
          placeholder="Укажите имя"
          size="large"
        />
      </AFormItem>

      <AFormItem label="Отчество" name="name">
        <AInput
          v-model.null-on-empty="formState.middle_name"
          type="email"
          placeholder="Укажите отчество"
          size="large"
        />
      </AFormItem>

      <AFormItem label="Фамилия" name="name">
        <AInput
          v-model.null-on-empty="formState.last_name"
          type="email"
          placeholder="Укажите фамилию"
          size="large"
        />
      </AFormItem>
    </AForm>
    <template #footer>
      <ARow :gutter="8">
        <ACol :span="12">
          <AButton size="large" block type="default" @click="emit('cancel')">Отменить</AButton>
        </ACol>
        <ACol :span="12">
          <AButton size="large" block type="primary" @click="emit('submit', formState)"
            >Применить</AButton
          >
        </ACol>
      </ARow>
    </template>
  </APageDrawer>
</template>
