<script setup lang="ts">
import { onMounted, ref, watch } from "vue"
import dayjs from "dayjs"

import type { SummaryStatisticsFilter } from "@/modules/statistics"
import {
  OperationsStatisticsGraphs,
  RevenueStatisticsGraphs,
  StatisticsFiltes,
  useSummaryStatistics
} from "@/modules/statistics"
import { gridGutter, useAdoptable } from "@/package/ui-kit"
import { injectProfileStatus } from "@/modules/profile"

import { useGetStatisticsFilter } from "../../modules/statistics/hooks/use-get-statistics-filter"
import { useSaveStatisticsFilters } from "../../modules/statistics/hooks/use-save-statistics-filter"

const filters = ref<Partial<SummaryStatisticsFilter>>({
  selectedMainVariants: ["income", "outlay"],
  selectedRevenueVariants: ["revenue", "revenueIncrease"],
  date: [],
  operationCategories: []
})
const {
  operationsStatistics,
  revenueStatistics,
  detalization,
  statisticsLoading,
  statisticsRequestErrorMessage,
  getStatistics
} = useSummaryStatistics(filters)
const profileStatus = injectProfileStatus()

const { getFilters, isGettingFilters } = useGetStatisticsFilter()

onMounted(async () => {
  const response = await getFilters()
  const savedFilters = response.data.value?.data
  if (savedFilters) {
    const formattedSavedFilters = {
      date:
        savedFilters.date_to && savedFilters.date_from
          ? [dayjs(savedFilters.date_from, "DD-MM-YYYY"), dayjs(savedFilters.date_to, "DD-MM-YYYY")]
          : filters.value.date,
      selectedMainVariants: savedFilters.selectedMainVariants || filters.value.selectedMainVariants,
      selectedRevenueVariants:
        savedFilters.selectedRevenueVariants || filters.value.selectedRevenueVariants,
      bankAccountId: savedFilters.bank_account_id
        ? savedFilters.bank_account_id
        : filters.value.bankAccountId,
      projectId: savedFilters.project_id ? savedFilters.project_id : filters.value.projectId,
      operationCategories: savedFilters.operationCategories
        ? savedFilters.operationCategories
        : filters.value.operationCategories,
      legalEntityId: savedFilters.legal_entity_id
        ? savedFilters.legal_entity_id
        : filters.value.legalEntityId
    }

    Object.assign(filters.value, formattedSavedFilters)
  } else {
    await getStatistics()
  }
})

const { executeSave } = useSaveStatisticsFilters()

watch(
  () => [
    filters.value.operationCategories,
    filters.value.allCategories,
    filters.value.projectId,
    filters.value.bankAccountId,
    filters.value.date,
    filters.value.legalEntityId
  ],
  async () => {
    if (!isGettingFilters.value) {
      await getStatistics()
    }
  },
  { deep: true, immediate: false }
)

watch(
  [filters.value, filters],
  async () => {
    if (!profileStatus.value.asAdmin) {
      const formattedFilters = {
        date_from: filters.value.date?.[0]?.format("DD-MM-YYYY"),
        date_to: filters.value.date?.[1]?.format("DD-MM-YYYY"),
        bank_account_id: filters.value.bankAccountId ? filters.value.bankAccountId : undefined,
        project_id: filters.value.projectId ? filters.value.projectId : undefined,
        selectedMainVariants: filters.value.selectedMainVariants,
        selectedRevenueVariants: filters.value.selectedRevenueVariants,
        operationCategories: filters.value.operationCategories,
        allCategories: filters.value.allCategories,
        legal_entity_id: filters.value.legalEntityId
      }

      await executeSave(formattedFilters)
    }
  },
  { deep: true, immediate: false }
)
const isAdoptable = useAdoptable()
</script>

<template>
  <ARow :gutter="gridGutter">
    <ACol :span="24">
      <StatisticsFiltes v-model="filters" :style="{ marginTop: '32px', marginBottom: '24px' }" />
    </ACol>
  </ARow>
  <ASpace :size="16" direction="vertical" style="overflow-y: auto; overflow-x: hidden">
    <ACol v-if="!isAdoptable" flex="1 1 auto">
      <AFlex justify="flex-end" align="center" :style="{ height: '100%' }">
        <ACheckboxGroup
          v-model:value="filters.selectedMainVariants"
          :options="[
            { label: 'Доходы', value: 'income' },
            { label: 'Расходы', value: 'outlay' }
          ]"
        />
      </AFlex>
    </ACol>
    <ARow :gutter="gridGutter">
      <ACol :span="24">
        <OperationsStatisticsGraphs
          :is-loading="statisticsLoading"
          :data="operationsStatistics"
          :detalization="detalization"
          :show-income="filters?.selectedMainVariants?.includes('income')"
          :show-outlay="filters?.selectedMainVariants?.includes('outlay')"
          :show-revenue="filters?.selectedRevenueVariants?.includes('revenue')"
          :style="{ height: '100%' }"
          :error-message="statisticsRequestErrorMessage"
        />
      </ACol>
    </ARow>
    <ARow v-if="!isAdoptable" :gutter="gridGutter" :wrap="false">
      <ACol flex="1 1 auto">
        <AFlex justify="flex-end" align="center" :style="{ height: '100%' }">
          <ACheckboxGroup
            v-model:value="filters.selectedRevenueVariants"
            :options="[
              { label: 'Чистая прибыль', value: 'revenue' },
              { label: 'Чистая прибыль с нарастающим итогом', value: 'revenueIncrease' }
            ]"
          />
        </AFlex>
      </ACol>
    </ARow>
    <ARow :gutter="gridGutter">
      <ACol :span="24">
        <RevenueStatisticsGraphs
          :is-loading="statisticsLoading"
          :data="revenueStatistics"
          :detalization="detalization"
          :show-revenue="filters?.selectedRevenueVariants?.includes('revenue')"
          :show-revenue-incrase="filters?.selectedRevenueVariants?.includes('revenueIncrease')"
          :style="{ height: '100%' }"
          :error-message="statisticsRequestErrorMessage"
        />
      </ACol>
    </ARow>
  </ASpace>
</template>
