import type { BudgetFormInterface } from "@/modules/budget"

export const FORM_DEFAULT_STATE: BudgetFormInterface = {
  name: undefined,
  legalEntityId: undefined,
  projectId: undefined,
  //@todo: cleanup
  //@ts-ignore
  period: undefined,
  comment: undefined,
  categories: new Array<number>()
}
