import type { ImportFormField } from "../interfaces"

import type { FormValidator } from "@/package/validators"

export const useRuleset = (formFields: Array<ImportFormField>) => {
  const allFormFieldsValidator: (formFields: Array<ImportFormField>) => FormValidator = (
    formFields
  ) => {
    const validator: FormValidator = () =>
      new Promise((resolve, reject) => {
        for (const field of formFields) {
          if (!field.operationKey || !field.value) {
            reject("Заполните все добавленные поля")
            return
          }
        }
        resolve()
      })
    return validator
  }

  const ruleset = {
    fields: [
      {
        asyncValidator: allFormFieldsValidator(formFields),
        trigger: "submit"
      }
    ]
  }

  return { ruleset }
}
