<script setup lang="ts">
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons-vue"
import { useRouter } from "vue-router"
import { computed, h, onUnmounted, ref, watch } from "vue"
import { TransitionFade } from "@morev/vue-transitions"
import { push } from "notivue"
import { noop } from "@vueuse/core"
import { storeToRefs } from "pinia"

import {
  LegalProfileCreateRouteName,
  LegalProfileDetailRouteName,
  LegalProfileEditRouteName
} from "@/router"
import { LegalEntityListing, useLegalEntityStore } from "@/modules/legal-entity"
import { DeleteConfirm, useAdoptable } from "@/package/ui-kit"
import { DEFAULT_REQUEST_ERROR_MESSAGE } from "@/interfaces"

const router = useRouter()
const listingRef = ref<InstanceType<typeof LegalEntityListing> | null>(null)
const store = useLegalEntityStore()
const { legalEntityListDeleteError, legalEntityDeleteError } = storeToRefs(store)

const selectedCount = computed(() => {
  let result = null
  if (listingRef.value?.selectedCount && listingRef.value?.selectedCount > 0) {
    result = `Выбрано: ${listingRef.value?.selectedCount} из ${store.paginationTotal}`
  }
  return result
})

const deleteAction = () => {
  DeleteConfirm({
    content: "Вы действительно хотите безвозвратно удалить выбранные записи?",
    onOk: () => {
      if (listingRef.value !== null) {
        let resCb: (args: any) => Promise<void> = () => Promise.resolve()
        if (listingRef.value) {
          if (listingRef.value.excludeKeysMode) {
            resCb = store.deleteByExcludedIdList
          } else {
            resCb = store.deleteBySelectedIdList
          }
        }
        return resCb(listingRef.value.currentModeKeys)
          .then(() => {
            push.success({ message: "Удалено" })
            listingRef.value?.unselectAllAction()
          })
          .catch(noop)
      }
    }
  })
}

watch(legalEntityListDeleteError, (error) => {
  if (error) {
    push.error({ message: error.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})

watch(legalEntityDeleteError, (error) => {
  if (error) {
    push.error({ message: error.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})

const allChecked = computed({
  get: () => listingRef.value?.isAllSelected,
  set: (value) => {
    listingRef.value?.toggleAllSelected(value)
  }
})
const openCreatePage = () => router.push({ name: LegalProfileCreateRouteName })
onUnmounted(store.$reset)
const isAdoptable = useAdoptable()
</script>

<template>
  <ARow justify="space-between" :style="{ marginTop: '32px' }">
    <ACol :xs="24" :md="12">
      <AFlex :gap="16" justify="space-between" :style="{ width: '100%' }">
        <template v-if="isAdoptable">
          <ACheckbox v-model:checked="allChecked">Выбрать все</ACheckbox>
          <div v-if="selectedCount !== null">
            {{ selectedCount }}
          </div>
        </template>
        <template v-else>
          <AButton default-styling size="large" type="primary" @click="openCreatePage">
            Создать
          </AButton>
        </template>
      </AFlex>
    </ACol>
  </ARow>
  <ARow v-if="!isAdoptable" justify="space-between" :style="{ marginTop: '16px' }">
    <ACol :span="12" :style="{ minHeight: '32px' }">
      <TransitionFade>
        <AFlex v-if="selectedCount !== null" :gap="16" align="center">
          <div>{{ selectedCount }}</div>
          <AButton type="text" danger :icon="h(DeleteOutlined)" @click="deleteAction"
            >Удалить</AButton
          >
        </AFlex>
      </TransitionFade>
    </ACol>
  </ARow>
  <LegalEntityListing
    ref="listingRef"
    :style="{ flexGrow: 1, marginTop: '16px' }"
    @openDetail="(id) => router.push({ name: LegalProfileDetailRouteName, params: { id } })"
    @openEdit="(id) => router.push({ name: LegalProfileEditRouteName, params: { id } })"
    @openCreate="() => router.push({ name: LegalProfileCreateRouteName })"
  />
  <AListingControlPanel v-if="isAdoptable">
    <AButton size="large" :icon="h(PlusOutlined)" type="primary" @click="openCreatePage" />
    <TransitionFade>
      <AButton
        v-if="selectedCount !== null"
        type="default"
        size="large"
        :icon="h(DeleteOutlined)"
        danger
        @click="deleteAction"
      >
        Удалить
      </AButton>
    </TransitionFade>
  </AListingControlPanel>
  <RouterView />
</template>
