import type { JwtPayload } from "jwt-decode"

export enum UserRole {
  Manager = "manager",
  Admin = "admin",
  ServiceManager = "service_manager"
}
export interface Profile {
  id?: number
  first_name: string
  last_name: string
  middle_name?: string | null
  email: string
  phone: string
  role: UserRole
}
export type EditableProfile = Omit<Profile, "role">

export interface ChangePasswordRequest {
  current_password: string
  new_password: string
  new_password_confirmation: string
}

export enum ProfileStatus {
  UNKNOWN,
  ACTIVE,
  UNCONFIRMED
}
export interface IPorfileStatus {
  attempts: number | null
  status: ProfileStatus
  deltedAt: null | Date
  asAdmin: boolean
}

export interface UnavailableProfileErrorPayload {
  attempts: null
  data: Profile
  deletion_date: number
}

export interface JwtPayloadWithScopes extends JwtPayload {
  scopes: Array<string>
}

export const ProfileRolesMap = {
  [UserRole.Manager]: "физ. лицо",
  [UserRole.Admin]: "Администратор",
  [UserRole.ServiceManager]: "Руководитель сервиса"
}
