import { useAxios } from "@vueuse/integrations/useAxios"

import type { OperationCategoryCreate } from "@/modules/operation-category"
import { client, useApiError } from "@/package/api-client"

export const useOperationCategoryCreate = () => {
  const { execute, isLoading, error, isFinished } = useAxios<
    { success: boolean; operation_category_id: number },
    { data: OperationCategoryCreate }
  >("/operation-categories", { method: "POST" }, client, { immediate: false })

  const transformFormDataToRequest = (data: OperationCategoryCreate): OperationCategoryCreate => ({
    name: data.name,
    type: data.type || null,
    parent_id: data.parent_id || null,
    comment: data.comment || null,
    action_type: data.action_type
  })

  const createOperationCategories = async (formData: OperationCategoryCreate): Promise<number> => {
    const data = transformFormDataToRequest(formData)
    const response = await execute({ data })
    return response.data!.value!.operation_category_id
  }

  return {
    createOperationCategories,
    isCreating: isLoading,
    createError: useApiError(error),
    isCreateFinished: isFinished
  }
}
