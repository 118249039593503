<script setup lang="ts">
import { h, watch } from "vue"
import { RetweetOutlined } from "@ant-design/icons-vue"
import { useRouter } from "vue-router"
import { useToggle } from "@vueuse/core"
import { theme } from "ant-design-vue"
import { OverlayScrollbarsComponent } from "overlayscrollbars-vue"
import { push } from "notivue"

import { FinanceOperationsRouteName } from "@/router"
import { OperationBanks, useOperationStore } from "@/modules/operation"
import { AButton } from "@/package/ui-kit"
import { DEFAULT_REQUEST_ERROR_MESSAGE } from "@/interfaces"

import { useBankIntegrationSync } from "../../../modules/operation/hooks/use-bank-integration-sync"

const router = useRouter()
const openListingAction = () => router.push({ name: FinanceOperationsRouteName })

const store = useOperationStore()

const [isOpened] = useToggle(true)

const { token } = theme.useToken()

const { syncBankIntegration, syncBankDataError } = useBankIntegrationSync()

const syncHandler = async () => {
  const connectedBanks = store.banksList.filter((el) => el?.is_connected)
  if (connectedBanks.length === 0) {
    push.error({ message: "Нет подключенных банков" })
    return
  }
  try {
    await Promise.all(
      connectedBanks.map(async (el) => {
        await syncBankIntegration(el?.user_integration_id as number)
      })
    )

    push.success({ message: "Синхронизация прошла успешно" })
  } catch (error) {
    push.error({ message: (error as { message: string }).message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
}

watch(syncBankDataError, (error) => {
  if (error) {
    push.error({ message: (error as { message: string }).message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})
</script>

<template>
  <APageDrawer
    v-model:open="isOpened"
    title="Подключение синхронизации с банком"
    custom-scroll-body
    :body-style="{
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      '--body-color': token.colorBgBase
    }"
    :footer-style="{
      minHeight: '57px'
    }"
    @onClose="openListingAction"
  >
    <OverlayScrollbarsComponent
      :style="{ maxHeight: '100%', padding: '24px', backgroundColor: token.colorBgBase }"
    >
      <AButton block size="large" :icon="h(RetweetOutlined)" @click="syncHandler"
        >Синхронизировать все</AButton
      >
      <OperationBanks />
    </OverlayScrollbarsComponent>

    <template #footer> </template>
  </APageDrawer>
</template>

<!--suppress CssUnresolvedCustomProperty -->
<style scoped></style>
