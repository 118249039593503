import type { OperationFileData } from "../interface"

import { useAxios } from "@vueuse/integrations/useAxios"
import { get } from "@vueuse/core"

import { client, useApiError } from "@/package/api-client"

export function useFileUpload() {
  const { execute, error, data } = useAxios(
    "/storage/upload-attachment",
    { method: "POST", headers: { "content-type": "multipart/form-data" } },
    client,
    {
      immediate: false
    }
  )

  const upload = async (options: any): Promise<OperationFileData | null> => {
    const { onError, file } = options

    const fmData = new FormData()
    fmData.append("file", file)

    let result = null
    try {
      await execute({ data: fmData })
      if (get(data)?.data?.file_storage_item_id) {
        result = {
          id: data.value?.data?.file_storage_item_id,
          name: file.name,
          type: file.type,
          size: file.size
        }
      }
    } catch (err) {
      onError({ err })
    }
    return result
  }
  const uploadError = useApiError(error, "Что-то пошло не так")

  return {
    upload,
    uploadError
  }
}
