<script setup lang="ts">
import { computed, h, onBeforeUnmount, onMounted, type PropType, ref, watch } from "vue"
import { debounce, throttle } from "lodash-es"
import { TransitionFade } from "@morev/vue-transitions"
import { useToggle } from "@vueuse/core"
import { storeToRefs } from "pinia"
import { push } from "notivue"

import { AFakeOption, VNodeRender } from "@/package/ui-kit"
import { useBankAccountStore, CreateBankAccountForm } from "@/modules/bank-account"
import { DETAIL_REQUEST_ERROR_MESSAGE } from "@/modules/operation"
import { ApiNotFoundResponse } from "@/package/api-client"

const props = defineProps({
  hideLink: {
    type: Boolean,
    default: false
  },

  excludedList: {
    type: Array as PropType<Array<number | string>>,
    default: () => []
  }
})
const selectedId = defineModel({
  type: Number,
  get(v) {
    if (store.isListLoading && store.listing.length === 0) {
      return -1
    }
    return v
  }
})

const [isSelectOpen, toggleSelectOpen] = useToggle(false)

const [isFormOpen, toggleFormOpen] = useToggle(false)
const formRef = ref<InstanceType<typeof CreateBankAccountForm> | null>(null)

const createBankAccount = () => {
  formRef?.value?.submitAction()
}

const cancelCreateForm = () => {
  toggleFormOpen(false)
}

const selectNewLegalBankAccount = async (newId: number) => {
  selectedId.value = newId
  toggleFormOpen(false)
}

const getContainer = (): HTMLElement | null =>
  document.querySelector(
    ".bank-account-drawer .ant-drawer-body div[data-overlayscrollbars-contents]"
  )
const openForm = () => {
  toggleSelectOpen(false)
  toggleFormOpen(true)
}
const store = useBankAccountStore()
const { listingApiError } = storeToRefs(store)

const options = computed(() =>
  store.isListLoading && store.formattedListing.length === 0
    ? [{ label: "Загрузка...", value: -1 }]
    : store.listing
        .filter(({ id }) => !props.excludedList.includes(id))
        .map((item) => ({ label: item.name, value: item.id }))
)
const LoadingOption = () =>
  h(AFakeOption, { disabled: true }, () =>
    store.paginationTotal
      ? `Список счетов загружается, ${store.currentOffset} из ${store.paginationTotal}`
      : "Список счетов загружается"
  )

watch(
  listingApiError,
  async (err) => {
    if (err) {
      push.error({ message: err.message ?? DETAIL_REQUEST_ERROR_MESSAGE })
    }
  },
  { immediate: true }
)

watch([selectedId, store.listing], async ([id]) => {
  if (id && id > -1 && !store.idList.includes(id) && (store.listing.length || store.isLoadedFull)) {
    try {
      await store.loadOne(id)
    } catch (e) {
      if (e instanceof ApiNotFoundResponse) {
        selectedId.value = undefined
      } else {
        push.error({ message: "Не удалось загрузить информацию о счету" })
      }
    }
  }
})

const scrollAction = throttle(async (e: any) => {
  if (
    e?.target?.scrollTop &&
    e?.target?.scrollHeight &&
    !store.isListLoading &&
    !store.isLoadedFull
  ) {
    const scrolled = e.target.scrollHeight - e.target.scrollTop
    const scrollHalf = e.target.scrollHeight / 4
    if (scrolled <= scrollHalf) {
      await store.loadBufferNextPage()
    }
  }
}, 1000)
const searchAction = debounce(store.requestSearch, 1000)
const cancelAction = () => {
  scrollAction.flush()
  searchAction.flush()
}

const resetSearch = (ids?: Array<number> | number) => {
  if (Array.isArray(ids) && ids.length === 0) {
    store.requestSearch("")
  } else if (!ids) {
    store.requestSearch("")
  }
}

onMounted(async () => {
  if (!store.listing.length && !store.isLoadedFull) {
    await store.loadBufferNextPage()
  }
})
onBeforeUnmount(store.$reset)
</script>

<template>
  <ASelect
    v-model:value="selectedId"
    v-model:open="isSelectOpen"
    size="large"
    :options="options"
    :loading="store.isListLoading"
    :default-active-first-option="false"
    show-search
    allow-clear
    :filter-option="false"
    @search="searchAction"
    @popupScroll="scrollAction"
    @blur="cancelAction"
    @dropdownVisibleChange="toggleSelectOpen"
    @focus="() => toggleSelectOpen(true)"
    @change="resetSearch"
  >
    <template #dropdownRender="{ menuNode }">
      <APageDrawer
        v-if="isFormOpen"
        v-model:open="isFormOpen"
        title="Создание счета"
        class-name="bank-account-drawer"
      >
        <CreateBankAccountForm
          ref="formRef"
          :get-container="getContainer"
          @success="selectNewLegalBankAccount"
        />
        <template #footer>
          <ARow :gutter="[8, 8]">
            <ACol :sm="24" :md="12" flex="1 1 50%">
              <AButton size="large" block @click="cancelCreateForm">Отмена</AButton>
            </ACol>
            <ACol :sm="24" :md="12" flex="1 1 50%">
              <AButton
                size="large"
                block
                type="primary"
                :loading="formRef?.isCreating"
                @click="createBankAccount"
                >Создать</AButton
              >
            </ACol>
          </ARow>
        </template>
      </APageDrawer>
      <AButton v-if="!hideLink" type="link" @click="openForm">Создать новый счет</AButton>
      <VNodeRender :node="menuNode" />
      <TransitionFade :easing="{ enter: '1s', leave: '1s' }">
        <LoadingOption v-if="store.isListLoading" />
      </TransitionFade>
    </template>
  </ASelect>
</template>
