const ACCESS_STORE_KEY = "access-token"
const REFRESH_STORE_KEY = "refresh-token"
const BACKUP_REFRESH_STORE_KEY = "backup-refresh-token"
const BACKUP_ACCESS_STORE_KEY = "backup-access-token"

let isSessionModeEnabled = false

export const setSessionMode = (sessionable: boolean) => {
  isSessionModeEnabled = sessionable
}

export const setRefreshToken = (token: string) => {
  if (!isSessionModeEnabled) {
    window.localStorage.setItem(REFRESH_STORE_KEY, token)
  }
}
export const getRefresh = () =>
  isSessionModeEnabled ? null : window.localStorage.getItem(REFRESH_STORE_KEY)
export const clearRefresh = () => {
  window.localStorage.removeItem(REFRESH_STORE_KEY)
}

export const backupRefreshToken = () => {
  const token = getRefresh()
  if (token) {
    window.localStorage.setItem(BACKUP_REFRESH_STORE_KEY, token)
  }
}

export const setAccessToken = (token: string) => {
  window.localStorage.setItem(ACCESS_STORE_KEY, token)
}
export const getAccessToken = () => window.localStorage.getItem(ACCESS_STORE_KEY)
export const clearAccess = () => {
  window.localStorage.removeItem(ACCESS_STORE_KEY)
}

export const backupAccessToken = () => {
  const token = getAccessToken()
  if (token) {
    window.localStorage.setItem(BACKUP_ACCESS_STORE_KEY, token)
  }
}

export const clearBackup = () => {
  window.localStorage.removeItem(BACKUP_ACCESS_STORE_KEY)
  window.localStorage.removeItem(BACKUP_REFRESH_STORE_KEY)
}

export const useBackupTokens = () => {
  const accessToken = window.localStorage.getItem(BACKUP_ACCESS_STORE_KEY)
  const refreshToken = window.localStorage.getItem(BACKUP_REFRESH_STORE_KEY)
  if (accessToken && refreshToken) {
    setAccessToken(accessToken)
    setRefreshToken(refreshToken)
  }
  clearBackup()
}

export const hasAuth = () =>
  (!isSessionModeEnabled && Boolean(getRefresh())) || Boolean(getAccessToken())
export const cleanAuth = () => {
  clearRefresh()
  clearAccess()
  clearBackup()
}
