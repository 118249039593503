<script setup lang="ts">
import { h, onUnmounted, watch } from "vue"
import { DeleteOutlined, InboxOutlined, PaperClipOutlined } from "@ant-design/icons-vue"
import { Form, theme } from "ant-design-vue"
import { noop } from "@vueuse/core"
import { push } from "notivue"

import { DEFAULT_REQUEST_ERROR_MESSAGE } from "@/interfaces"

import { useRuleset, useFileUpload } from "./hooks"
import { useOperationImportStore } from "./store"
import ImportExample from "./ImportExample.vue"

interface SelectOption {
  value: string
  label: string
}

const { token } = theme.useToken()

const store = useOperationImportStore()
const { ruleset } = useRuleset(store.formFields)

const { validate, validateInfos } = Form.useForm({ fields: null }, ruleset)

const { upload, uploadError } = useFileUpload()

watch(uploadError, (err) => {
  if (err) {
    push.error({ message: err.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})

const submitAction = () =>
  validate()
    .then(() => store.importOperations())
    .catch(noop)

defineExpose({ submitAction })

onUnmounted(store.$reset)
</script>

<template>
  <AForm
    name="create-product-form"
    :label-col="{ span: 24 }"
    :wrapper-col="{ span: 24 }"
    autocomplete="off"
    :colon="false"
    :style="{ padding: '24px' }"
    scroll-to-first-error
  >
    <AUploadDragger
      :file-list="store.files"
      :class="store.files.length > 0 ? 'hidden-upload' : ''"
      name="file"
      :multiple="false"
      :custom-request="upload"
    >
      <p class="ant-upload-drag-icon">
        <InboxOutlined style="width: 32px; height: 32px" />
      </p>
      <p class="ant-upload-text">Нажмите или перетащите файл для загрузки</p>
      <AText type="secondary">Загрузите файл в формате .xlsx, .xls</AText>
      <template #itemRender="{ file }">
        <ASpace style="width: 100%; display: flex; justify-content: space-between">
          <ASpace>
            <PaperClipOutlined />
            <ALink :href="file.url" :download="file.name">{{ file.name }}</ALink>
          </ASpace>
          <AButton :icon="h(DeleteOutlined)" type="text" @click="store.removeFile" />
        </ASpace>
      </template>
    </AUploadDragger>
    <ImportExample />
    <template v-for="formField in store.formFields" :key="formField.id">
      <AInputGroup compact style="margin-top: 16px">
        <ARow style="display: flex; align-items: center; max-width: 100%">
          <ACol span="10">
            <AText v-if="formField.operationKey?.label">{{ formField.operationKey?.label }}</AText>
            <ASelect
              v-else
              placeholder="Выберите поле"
              size="large"
              :options="store.filteredFieldNameOptions"
              style="width: 90%"
              @select="
                (value: string, option: SelectOption) => {
                  formField.operationKey = { label: option.label, value: value }
                }
              "
            />
          </ACol>
          <ACol span="14">
            <ASelect
              v-model:value="formField.value"
              :style="{ width: formField.id <= 2 ? '100%' : 'calc(100% - 56px)' }"
              :options="store.filteredExcelColumnOptions"
              :disabled="store.excelColumnOptionList.length === 0"
              placeholder="Выберите название столбца"
              size="large"
            />
            <AButton
              v-if="formField.id > 2"
              :style="{ marginLeft: '16px', width: '40px' }"
              size="large"
              type="primary"
              danger
              :icon="h(DeleteOutlined)"
              @click="() => store.deleteField(formField.id)"
            />
          </ACol>
        </ARow>
      </AInputGroup>
    </template>
    <AButton
      v-if="store.canAddMoreFields"
      type="text"
      :style="{ color: token.colorPrimary, padding: 0 }"
      @click="store.addField"
      >Добавить поле</AButton
    >
    <template
      v-if="
        validateInfos.fields.validateStatus === 'error' ||
        validateInfos.fields.validateStatus === 'validating'
      "
    >
      <div class="form-fields-global-error">Заполните все добавленные поля</div>
    </template>
  </AForm>
</template>

<style scoped lang="scss">
:deep(.ant-checkbox-group) {
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
}

.hidden-upload:deep(.ant-upload) {
  display: none;
}

.form-fields-global-error {
  text-align: right;
  margin-top: 8px;
  gap: 8px;
  justify-items: end;
  color: v-bind("token.colorErrorText");
}
</style>
