<script setup lang="ts"></script>

<template>
  <div class="example-block">
    <AText strong>Проведение соответствий</AText>
    <AText style="color: #161615a6; font-size: 14px"
      >После загрузки файла вам необходимо провести соответствия между вашим файлом и системой. Для
      этого в поле впишите название столбца, который соответствует данным в нашей системе.
      Например:</AText
    >
    <ARow style="display: flex; align-items: center; max-width: 100%">
      <ACol span="9">
        <AText>Дата операции</AText>
      </ACol>
      <ACol span="15">
        <AInput value="Дата" disabled class="example-field" size="large" />
      </ACol>
    </ARow>
    <ARow style="display: flex; align-items: center; max-width: 100%">
      <ACol span="9">
        <AText>Сумма</AText>
      </ACol>
      <ACol span="15">
        <AInput value="Сумма" disabled class="example-field" size="large" />
      </ACol>
    </ARow>
  </div>
</template>

<style scoped lang="scss">
.example-block {
  border: 1px #7ab4ff solid;
  border-radius: 8px;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
}

.example-field.ant-input-disabled {
  color: black;
  background-color: inherit;
}
</style>
