import { onBeforeUpdate, ref, type VNodeRef } from "vue"

import { OperationAction, OperationActionDict } from "@/modules/operation"

import IncomingCreateForm from "../ui/IncomingCreateForm.vue"
import OutgoingCreateForm from "../ui/OutgoingCreateForm.vue"
import TransferCreateForm from "../ui/TransferCreateForm.vue"

export const useCreateOperationTabs = () => {
  const current = ref(OperationAction.incoming)

  const tabList = [
    {
      key: OperationAction.incoming,
      tab: OperationActionDict[OperationAction.incoming],
      form: IncomingCreateForm
    },
    {
      key: OperationAction.outgoing,
      tab: OperationActionDict[OperationAction.outgoing],
      form: OutgoingCreateForm
    },
    {
      key: OperationAction.transfer,
      tab: OperationActionDict[OperationAction.transfer],
      form: TransferCreateForm
    }
  ]

  let formList = new Map()
  const setFormRef = (key: OperationAction, el?: VNodeRef) => {
    if (el) {
      formList.set(key, el)
    }
  }
  onBeforeUpdate(() => {
    formList = new Map()
  })
  const submitAction = () => formList.get(current.value)?.submitAction()

  return {
    current,
    tabList,
    submitAction,
    setFormRef
  }
}
