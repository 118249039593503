import type { ColumnsType } from "ant-design-vue/es/table"

import { computed, type Ref } from "vue"
import { type TableProps, Tag } from "ant-design-vue"
import { h, ref, watch } from "vue"
import { Flex, TypographyText } from "ant-design-vue"

import { AText, renderPlaceholder, useAdoptable, viewValueOrDefault } from "@/package/ui-kit"
import { type BankAccountSummary, useBankAccountStore } from "@/modules/bank-account"
import { SortOrder, SortOrderInternal } from "@/interfaces"

const defaultSizes = {}
const adoptedColumns = [
  {
    key: "id",
    customRender: ({ record }: { record: BankAccountSummary }) =>
      h(Flex, { vertical: true }, () => [
        h(TypographyText, {
          strong: true,
          content: viewValueOrDefault(record.name),
          ellipsis: true
        }),
        h(AText, {
          type: "secondary",
          ellipsis: true,
          style: { marginTop: "8px" },
          content: viewValueOrDefault(record.legal_entity.name)
        })
      ])
  }
]
const defaultColumns = (): ColumnsType<any> => {
  const store = useBankAccountStore()

  return [
    {
      title: "Название",
      key: "name",
      dataIndex: "name",
      ellipsis: { showTitle: true },
      customRender: renderPlaceholder,
      sorter: true,
      sortOrder: computed(() => {
        if (!store.sortOrder) {
          return null
        }
        return store.sortOrder === SortOrder.ASC
          ? SortOrderInternal.ASCEND
          : SortOrderInternal.DESCEND
      }) as unknown as SortOrderInternal | null
    },
    {
      title: "Юридическое лицо",
      dataIndex: ["legal_entity", "name"],
      ellipsis: { showTitle: true },
      customCell: (record) => record,
      customRender: renderPlaceholder
    },
    {
      title: "Банк",
      key: "bank_name",
      dataIndex: "bank_name",
      ellipsis: { showTitle: true },
      customRender: renderPlaceholder
    },
    {
      title: "БИК",
      key: "bic",
      dataIndex: "bic",
      ellipsis: { showTitle: true },
      customRender: renderPlaceholder
    },
    {
      title: "Расчетный счет",
      key: "account_number",
      dataIndex: "account_number",
      width: 200,
      ellipsis: { showTitle: true },
      customRender: renderPlaceholder
    },
    {
      title: "Статус",
      dataIndex: "active",
      ellipsis: { showTitle: true },
      customRender: ({ value }) =>
        h(Tag, { color: value ? "success" : "error" }, () =>
          viewValueOrDefault(value ? "Активен" : "Не активен")
        )
    },
    {
      title: "",
      key: "action",
      width: 64
    }
  ]
}
export const useColumns = () => {
  const columns: Ref<TableProps["columns"]> = ref()

  const handleResizeColumn: TableProps["onResizeColumn"] = (w, col) => {
    col.width = w
  }
  const resetColSizesAction = () => {
    if (columns.value) {
      for (const col of columns.value) {
        const key = col.key
        if (typeof key === "string" && key in defaultSizes) {
          col.width = defaultSizes[key as keyof typeof defaultSizes]
        }
      }
    }
  }
  const isAdoptable = useAdoptable()
  watch(
    isAdoptable,
    (adopted) => {
      if (adopted) {
        columns.value = adoptedColumns
      } else {
        columns.value = defaultColumns()
      }
    },
    { immediate: true }
  )

  return {
    resetColSizesAction,
    handleResizeColumn,
    columns
  }
}
