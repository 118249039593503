<script setup lang="ts">
import { computed, type PropType } from "vue"

import { type OperationCategoryDetail, useCategoryTypes } from "@/modules/operation-category"
import { viewValueOrDefault } from "@/package/ui-kit"

const props = defineProps({
  operation: {
    type: Object as PropType<OperationCategoryDetail | undefined>,
    default: () => undefined
  }
})

const { data } = useCategoryTypes()
const type = computed(
  () => (data.value ?? []).find(({ slug }) => slug === props.operation?.type)?.value
)
</script>

<template>
  <ADescriptions layout="vertical" :column="24">
    <ADescriptionsItem :span="24" label="Название">{{ operation?.name }}</ADescriptionsItem>
    <ADescriptionsItem :span="24" label="Родительская статья">
      {{ viewValueOrDefault(operation?.parentName) }}
    </ADescriptionsItem>
    <ADescriptionsItem :span="24" label="Тип">{{ viewValueOrDefault(type) }}</ADescriptionsItem>
    <ADescriptionsItem :span="24" label="Комментарий" :content-style="{ whiteSpace: 'normal' }">
      {{ viewValueOrDefault(operation?.comment) }}
    </ADescriptionsItem>
  </ADescriptions>
</template>
