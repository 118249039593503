<script setup lang="ts">
import type { Form } from "ant-design-vue"

import { computed, type PropType, ref, toRefs, watch } from "vue"
import { push } from "notivue"
import { storeToRefs } from "pinia"

import { LegalEntitySelect } from "@/modules/legal-entity"
import { usePopupContainerGetter } from "@/package/ui-kit"
import { useForm } from "@/package/hooks"
import { DEFAULT_REQUEST_ERROR_MESSAGE } from "@/interfaces"
import { isValidateError } from "@/package/util"

import { useBankAccountStore } from "../../store/bank-account.store"
import { useBankAutocomplete } from "../../hooks/use-bank-autocomplete"

import { useFormState } from "./use-form-state"
import { useRuleset } from "./use-ruleset"

const props = defineProps({
  getContainer: {
    type: [Function, undefined] as PropType<() => HTMLElement | null>,
    default: undefined
  }
})
const emit = defineEmits(["success"])
const { getContainer } = toRefs(props)

const { formState, requestData } = useFormState()
const ruleset = useRuleset(formState)
const { validate, validateInfos, isValidating } = useForm(formState, ruleset)
const { bankDataRequestErrorMessage } = useBankAutocomplete(formState, validate)

const store = useBankAccountStore()

const formRef = ref<typeof Form | null>(null)

const submitAction = () =>
  validate()
    .then(() => store.createBankAccount(requestData.value))
    .then(async () => {
      push.success({
        message: "Банковский счет создан"
      })
      const result = await store.updateBufferAfterCreate()
      if ((result?.id && result?.name) || store.sortUsed) {
        emit("success", result?.id, result?.name)
      } else {
        push.error({
          message: "Банковский счет не создан, сообщите в поддерджку"
        })
      }
    })
    .catch((err) => {
      if (isValidateError(err)) {
        formRef.value?.scrollToField(err.errorFields[0].name, {
          block: "start",
          boundary: (parent: HTMLElement) => {
            parent.focus()
            return parent.classList.contains("ant-row")
          }
        })
      }
    })

const { isCreating, createError, isCreateFinished } = storeToRefs(store)

watch(bankDataRequestErrorMessage, (err) => {
  if (err) {
    validateInfos.bic.help = [err]
    validateInfos.bic.validateStatus = "error"
  }
})
watch([createError, isCreateFinished], ([err, finishedStatus]) => {
  if (err && finishedStatus) {
    isCreateFinished.value = false
    push.error({ message: err.message ?? DEFAULT_REQUEST_ERROR_MESSAGE })
  }
})

defineExpose({
  submitAction,
  isCreating: computed(() => isCreating.value || isValidating.value)
})

const popupContainerGetter = usePopupContainerGetter(getContainer?.value)
</script>

<template>
  <AForm
    ref="formRef"
    name="create-bank-account"
    :label-col="{ span: 24 }"
    :wrapper-col="{ span: 24 }"
    autocomplete="off"
    :colon="false"
    :style="{ marginTop: '12px' }"
    scroll-to-first-error
    @submit.prevent="submitAction"
    @keydown.enter.prevent
  >
    <AFormItem label="Название" name="name" v-bind="validateInfos?.name">
      <AInput v-model="formState.name" size="large" placeholder="Введите название" />
    </AFormItem>
    <AFormItem
      label="Юридическое лицо"
      name="legal_entity_id"
      v-bind="validateInfos?.legal_entity_id"
    >
      <LegalEntitySelect
        v-model.null-on-empty="formState.legal_entity_id"
        :get-popup-container="popupContainerGetter"
        placeholder="Выберите юридическое лицо"
      />
    </AFormItem>
    <AFormItem label="БИК" name="bic" v-bind="validateInfos?.bic">
      <AInput
        v-model.numbered="formState.bic"
        size="large"
        placeholder="Введите БИК"
        :maxlength="9"
      />
    </AFormItem>
    <AFormItem label="Банк" name="bank_name" v-bind="validateInfos?.bank_name">
      <AInput
        v-model="formState.bank_name"
        disabled
        size="large"
        placeholder="Автозаполнение данных"
      />
    </AFormItem>
    <AFormItem
      label="Корреспондентский счет"
      name="correspondent_account_number"
      v-bind="validateInfos?.correspondent_account_number"
    >
      <AInput
        v-model="formState.correspondent_account_number"
        disabled
        size="large"
        placeholder="Автозаполнение данных"
      />
    </AFormItem>
    <AFormItem label="Расчетный счет" name="account_number" v-bind="validateInfos?.account_number">
      <AInput
        v-model.numbered="formState.account_number"
        size="large"
        placeholder="Введите расчетный счет"
        :maxlength="20"
      />
    </AFormItem>
  </AForm>
</template>
