<script setup lang="ts">
import type { BankData } from "../../interface"

import { onMounted, ref, watch } from "vue"
import { push } from "notivue"

import { DEFAULT_REQUEST_ERROR_MESSAGE } from "@/interfaces"

import { useOperationStore } from "../../store"

import OperationBanksItem from "./OperationBanksItem.vue"
import { useBanksList } from "./hooks/use-banks-get"

const { requestBanksList, isListLoading, banksListResponse, listingError } = useBanksList()

const store = useOperationStore()

const getBanksList = async () => {
  await requestBanksList()
  store.updateBanksList(banksListResponse.value?.data)
}

onMounted(async () => getBanksList())

watch(
  listingError,
  (error) => {
    if (error) {
      const errorMessage = (error as { message: string }).message ?? DEFAULT_REQUEST_ERROR_MESSAGE
      push.error({ message: errorMessage })
    }
  },
  { immediate: true }
)

const skeletons = ref([...Array(6).keys()])
</script>

<template>
  <template v-if="isListLoading">
    <AFlex v-for="skeleton in skeletons" :key="skeleton" :style="{ marginTop: '16px' }">
      <ASkeletonAvatar active size="large" shape="circle" :style="{ marginRight: '8px' }" />
      <ASkeletonButton active size="large" block />
    </AFlex>
  </template>
  <template v-else>
    <OperationBanksItem
      v-for="bank in banksListResponse?.data as BankData[]"
      :key="bank.id"
      :loading="isListLoading"
      :variant="bank.type"
      :is-connected="bank.is_connected"
      :bank-name="bank.name"
      :auth-type="bank.auth_type"
      :integration-id="bank.user_integration_id"
      @refetch="getBanksList"
    />
  </template>
</template>
