<script setup lang="ts">
import type { LegalEntityCreate } from "../../interfaces"
import type { Form } from "ant-design-vue"

import { onMounted, type PropType, ref, toRefs, watch } from "vue"
import { useDebounceFn } from "@vueuse/core"

import { usePopupContainerGetter } from "@/package/ui-kit"
import { useForm } from "@/package/hooks"
import { isValidateError } from "@/package/util"
import { useBankAutocomplete } from "@/modules/bank-account"

import {
  autocompletePlaceholder,
  useLegalEntityAutocomplete
} from "../../hooks/use-legal-entity-autocomplete"
import TurnoverSelect from "../TurnoverSelect.vue"
import LegalEntitySearch from "../LegalEntitySearch.vue"
import OkvedSearch from "../OkvedSearch.vue"

import { useFormState } from "./use-form-state"
import { searchConnectedFields, useRuleset } from "./use-ruleset"

const props = defineProps({
  profile: {
    type: [Object, undefined] as PropType<LegalEntityCreate | undefined>,
    default: () => undefined
  },

  getContainer: {
    type: [Function, undefined] as PropType<() => HTMLElement | null>,
    default: undefined
  }
})

const emit = defineEmits(["submit"])

const { getContainer } = toRefs(props)

const { formState, requestData } = useFormState()
const ruleset = useRuleset(formState)

const resetAction = useDebounceFn(() => {
  if (props.profile) {
    Object.assign(formState, props.profile)
  }
}, 100)
watch(() => props.profile, resetAction)
onMounted(resetAction)
const { validate, validateInfos } = useForm(formState, ruleset)
const { selectedEntity, disabledFields } = useLegalEntityAutocomplete(formState, false, validate)
const formRef = ref<typeof Form | null>(null)

const { bankDataRequestErrorMessage } = useBankAutocomplete(formState, validate)
watch(bankDataRequestErrorMessage, (err) => {
  if (err) {
    validateInfos.bic.help = [err]
    validateInfos.bic.validateStatus = "error"
  }
})

const submitAction = () =>
  validate()
    .then(() => emit("submit", requestData.value))
    .catch((err) => {
      if (isValidateError(err)) {
        const isSearchError = searchConnectedFields.includes(
          err.errorFields[0].name as unknown as string
        )
        const search = document.getElementById("legal-entity-search")
        if (isSearchError) {
          search?.focus()
        }

        formRef.value?.scrollToField(isSearchError ? "search" : err.errorFields[0].name, {
          block: "start",
          boundary: (parent: HTMLElement) => {
            if (isSearchError) {
              return true
            } else {
              parent.focus()
              return parent.classList.contains("ant-row")
            }
          }
        })
      }
    })

defineExpose({ submitAction, resetAction })
const popupContainerGetter = usePopupContainerGetter(getContainer?.value)
</script>

<template>
  <AForm
    ref="formRef"
    name="edit-legal-entity-form"
    :label-col="{ span: 24 }"
    :wrapper-col="{ span: 24 }"
    autocomplete="off"
    :colon="false"
    hide-required-mark
    :style="{ marginTop: '12px' }"
    scroll-to-first-error
    @submit.prevent="submitAction"
  >
    <AFormItem label="Поиск компании">
      <LegalEntitySearch
        v-model="selectedEntity"
        :get-popup-container="popupContainerGetter"
        :itn="formState.itn"
        :is-search-disabled="!!profile"
      />
    </AFormItem>
    <ADivider :style="{ marginTop: '6px' }" />
    <ATypographyParagraph type="secondary" :style="{ textAlign: 'center', marginTop: '16px' }">
      Данные ниже будут заполнены автоматически, после указания основной информации о юридическом
      лице
    </ATypographyParagraph>
    <AFormItem label="ИНН" name="itn" v-bind="validateInfos?.itn">
      <AInput
        v-model.numbered="formState.itn"
        :placeholder="autocompletePlaceholder"
        size="large"
        disabled
      />
    </AFormItem>
    <AFormItem
      label="Название организации"
      name="organization_name"
      v-bind="validateInfos?.organization_name"
    >
      <AInput
        v-model="formState.organization_name"
        size="large"
        disabled
        :placeholder="autocompletePlaceholder"
      />
    </AFormItem>
    <AFormItem label="ФИО руководителя" name="head_fullname" v-bind="validateInfos?.head_fullname">
      <AInput
        v-model="formState.head_fullname"
        size="large"
        :disabled="disabledFields.head_fullname"
        :placeholder="
          disabledFields.head_fullname ? autocompletePlaceholder : 'Введите ФИО руководителя'
        "
      />
    </AFormItem>
    <AFormItem
      label="Основной вид деятельности (ОКВЭД)"
      name="okved_id"
      v-bind="validateInfos?.okved_id"
    >
      <OkvedSearch
        v-model:okved_code="formState.okved_code"
        v-model:okved_id="formState.okved_id"
        :get-popup-container="popupContainerGetter"
        size="large"
        :disabled="disabledFields.okved_id"
        :placeholder="
          disabledFields.okved_id ? autocompletePlaceholder : 'Поиск по коду или наименованию ОКВЭД'
        "
      />
    </AFormItem>
    <AFormItem label="Юридический адрес" name="legal_address" v-bind="validateInfos?.legal_address">
      <AInput
        v-model="formState.legal_address"
        size="large"
        :disabled="disabledFields.legal_address"
        :placeholder="
          disabledFields.legal_address
            ? autocompletePlaceholder
            : 'Укажите индекс, город, улица, дом'
        "
      />
    </AFormItem>
    <AFormItem
      label="Размер ежемесячного оборота, руб"
      name="monthly_turnover_rate"
      v-bind="validateInfos?.monthly_turnover_rate"
    >
      <TurnoverSelect
        v-model:value="formState.monthly_turnover_rate"
        :get-popup-container="popupContainerGetter"
        size="large"
      />
    </AFormItem>
    <AFormItem label="БИК" name="bic" v-bind="validateInfos?.bic">
      <AInput
        v-model.numbered="formState.bic"
        :disabled="profile"
        size="large"
        placeholder="Введите БИК"
        :maxlength="9"
      />
    </AFormItem>
    <AFormItem label="Название банка" name="bank_name" v-bind="validateInfos?.bank_name">
      <AInput
        v-model="formState.bank_name"
        disabled
        size="large"
        :placeholder="autocompletePlaceholder"
      />
    </AFormItem>
    <AFormItem
      label="Корреспондентский счет"
      name="correspondent_account_number"
      v-bind="validateInfos?.correspondent_account_number"
    >
      <AInput
        v-model.numbered="formState.correspondent_account_number"
        disabled
        size="large"
        :placeholder="autocompletePlaceholder"
      />
    </AFormItem>
    <AFormItem label="Расчетный счет" name="account_number" v-bind="validateInfos?.account_number">
      <AInput
        v-model.numbered="formState.account_number"
        size="large"
        :disabled="profile"
        placeholder="Введите расчетный счет"
        :maxlength="20"
      />
    </AFormItem>
  </AForm>
</template>
