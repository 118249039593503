<script setup lang="ts">
import type { MenuProps } from "ant-design-vue"

import { h, computed } from "vue"
import { RouterLink, useRoute } from "vue-router"

import {
  BankAccountRouteName,
  CounterpartiesReferencesRouteName,
  ReferencesOperationsCategoriesRouteName,
  ReferencesProductsRouteName,
  ReferencesProjectsRouteName
} from "@/router"

const items: MenuProps["items"] = [
  {
    label: h(RouterLink, { to: "/references/counterparties" }, () => "Контрагенты"),
    key: CounterpartiesReferencesRouteName
  },
  {
    label: h(RouterLink, { to: "/references/bank-account" }, () => "Счета"),
    key: BankAccountRouteName
  },
  {
    label: h(RouterLink, { to: "/references/projects" }, () => "Проекты"),
    key: ReferencesProjectsRouteName
  },
  {
    label: h(
      RouterLink,
      { to: "/references/operation-categories" },
      () => "Статьи доходов и расходов"
    ),
    key: ReferencesOperationsCategoriesRouteName
  },
  {
    label: h(RouterLink, { to: "/references/products" }, () => "Товары и услуги"),
    key: ReferencesProductsRouteName
  }
]
const route = useRoute()

const current = computed(() => route.matched.map(({ name }) => name))
</script>

<template>
  <AMenu
    class="custom-menu"
    :selected-keys="current"
    :items="items"
    mode="horizontal"
    :style="{ marginTop: '8px' }"
  />
  <RouterView />
</template>

<style scoped lang="scss">
.custom-menu:deep(.ant-menu-item:first-child) {
  padding-left: 0 !important;

  &::after {
    left: 0;
  }
}
.custom-menu:deep(.ant-menu-item:nth-last-child(2)) {
  padding-right: 0 !important;
  &::after {
    right: 0;
  }
}
</style>
