<script setup lang="ts">
import type { SorterResult } from "ant-design-vue/es/table/interface"

import { computed, h, ref } from "vue"
import {
  DashOutlined,
  EditOutlined,
  LoginOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined
} from "@ant-design/icons-vue"
import { noop, useInfiniteScroll } from "@vueuse/core"
import { type TableProps, theme } from "ant-design-vue"
import { push } from "notivue"
import { storeToRefs } from "pinia"

import { DeleteConfirm, useAdoptable } from "@/package/ui-kit"
import { useBufferSelection } from "@/package/hooks"
import router, { FinanceStatistics } from "@/router"
import { useProfile, UserRole } from "@/modules/profile"
import {
  backupAccessToken,
  backupRefreshToken,
  setAccessToken,
  setRefreshToken
} from "@/package/api-client"

import { UserStatus, type UserSummary } from "../../interface"
import { useUserStore } from "../../store/user.store"

import { useColumns } from "./hooks/use-columns"
import { useLoginUserAs } from "./hooks/use-login-user-as"

const emit = defineEmits(["openDetail", "openEdit"])
const isAdoptable = useAdoptable()
const { columns } = useColumns()
const store = useUserStore()
const { idList, paginationTotal } = storeToRefs(store)
const { loginAs, data: loginData } = useLoginUserAs()
const {
  isAllSelected,
  selectedCount,
  selectedIdList,
  onSelect,
  onSelectAll,
  toggleAllSelected,
  unselectAllAction,
  currentModeKeys,
  excludeKeysMode
} = useBufferSelection(idList, paginationTotal)
const { refreshAsync, profile } = useProfile()

const rowSelectionConfig = computed(() => ({
  columnWidth: 32,
  onChange: onSelect,
  preserveSelectedRowKeys: true,
  selectedRowKeys: selectedIdList,
  onSelectAll
}))

const customRow: TableProps["customRow"] = (data: UserSummary) => ({
  onClick: () => {
    emit("openDetail", data.id)
  }
})

const tableWrapper = ref<HTMLElement | null>(null)
const stickyConfig = {
  offsetHeader: 0,
  offsetScroll: 0,
  getContainer: () => tableWrapper.value
}
useInfiniteScroll(tableWrapper, store.loadBufferNextPage, {
  distance: 1000,
  interval: 2000,
  canLoadMore: () => !store.isLoadedFull && !store.isSortingLoading && store.isFilteredReceived
})

const loginAsAction = async (id: number) => {
  await loginAs(id)
  backupAccessToken()
  backupRefreshToken()
  setAccessToken(loginData.value.access)
  setRefreshToken(loginData.value.refresh)
  await refreshAsync()
  await router.push({ name: FinanceStatistics })
}
const blockAction = (id: number) => {
  DeleteConfirm({
    title: "Блокировка пользователя",
    content: "Вы действительно хотите заблокировать пользователя?",
    okText: "Заблокировать",
    onOk: () =>
      store
        .blockOne(id)
        .then(() => {
          push.success({ message: "Пользователь заблокирован" })
        })
        .catch(noop)
  })
}
const activateAction = (id: number) => {
  DeleteConfirm({
    title: "Активация пользователя",
    content: "Вы действительно хотите активировать пользователя?",
    okText: "Активировать",
    icon: h(CheckCircleOutlined),
    onOk: () =>
      store
        .activateOne(id)
        .then(() => {
          push.success({ message: "Пользователь активирован" })
        })
        .catch(noop)
  })
}

defineExpose({
  unselectAllAction,
  selectedCount,
  isAllSelected,
  toggleAllSelected,
  currentModeKeys,
  excludeKeysMode
})
const { token } = theme.useToken()
</script>

<template>
  <div ref="tableWrapper" :style="{ overflow: 'auto', height: '100%' }">
    <ATable
      :size="isAdoptable ? 'middle' : 'large'"
      :sticky="stickyConfig"
      class="custom-table"
      :loading="store.isListLoading"
      :columns="columns"
      :data-source="store.listing"
      :show-header="!isAdoptable"
      :row-selection="rowSelectionConfig"
      :pagination="false"
      row-key="id"
      :custom-row="customRow"
      @change="
        async (pagination: any, filters: any, sorter: SorterResult) => {
          await store.setSortOrder(sorter)
          await store.saveUsersSorting()
        }
      "
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'action'">
          <ADropdown
            :overlay-style="{ zIndex: 100 }"
            placement="bottomRight"
            arrow
            :trigger="['hover']"
          >
            <AButton :icon="h(DashOutlined)" type="text" @click.stop />
            <template #overlay>
              <AMenu>
                <AMenuItem
                  :icon="h(EditOutlined)"
                  @click="() => emit('openEdit', (record as UserSummary).id)"
                >
                  Редактировать
                </AMenuItem>
                <template v-if="(record as UserSummary).status === UserStatus.active">
                  <AMenuItem
                    danger
                    :disabled="
                      store.isStatusChanging ||
                      profile?.role !== UserRole.Admin ||
                      profile.id === (record as UserSummary).id
                    "
                    :icon="h(CloseCircleOutlined)"
                    @click="() => blockAction((record as UserSummary).id)"
                  >
                    Заблокировать пользователя
                  </AMenuItem></template
                >
                <template v-else>
                  <AMenuItem
                    :style="{ color: token.colorSuccessText }"
                    :disabled="store.isStatusChanging"
                    :icon="h(CheckCircleOutlined)"
                    @click="() => activateAction((record as UserSummary).id)"
                  >
                    Активировать пользователя
                  </AMenuItem>
                </template>
                <AMenuItem
                  :disabled="(record as UserSummary).role !== UserRole.Manager"
                  :icon="h(LoginOutlined)"
                  @click="() => loginAsAction((record as UserSummary).id)"
                >
                  Войти под пользователем
                </AMenuItem>
              </AMenu>
            </template>
          </ADropdown>
        </template>
      </template>
      <template #emptyText>
        <AEmptyListingPlaceholder />
      </template>
    </ATable>
  </div>
</template>

<style scoped>
.custom-table {
  max-width: calc(100% - 2px);
}

.custom-table:deep(.ant-table-wrapper),
.custom-table:deep(.ant-spin-container),
.custom-table:deep(.ant-table-container),
.custom-table:deep(.ant-spin-nested-loading),
.custom-table:deep(.ant-table-content table) {
  height: 100%;
}

.custom-table:deep(.ant-spin-container) {
  display: flex;
  flex-direction: column;
}
</style>
