import type { BudgetFormInterface, BudgetRecord } from "./interfaces"

import { defineStore } from "pinia"
import { computed, type MaybeRef, ref, watch } from "vue"
import { get, set } from "@vueuse/core"
import { push } from "notivue"
import { HttpStatusCode } from "axios"

import { useListBufferApi, usePagination } from "@/package/hooks"

import {
  useBudgetCreate,
  useBudgetDelete,
  useBudgetEdit,
  useBudgetList,
  useBudgetListDelete
} from "./hooks"

export const useBudgetStore = defineStore("budgetStore", () => {
  const { pagination, paginationTotal, currentOffset, setPagination, decrementTotal } =
    usePagination()
  const listingBuffer = ref<Array<BudgetRecord>>([])
  const idList = computed(() => listingBuffer.value.map(({ id }) => id))

  const searchQuery = ref("")
  const searchQueryReadonly = computed(() => searchQuery.value)

  const {
    setUpdateFn,
    updateBufferFn,
    bufferConcat,
    bufferUnshift,
    bufferFilterOneConcat,
    bufferReplaceByFirst,
    bufferRewrite
  } = useListBufferApi(listingBuffer)

  const {
    executeListing,
    isListingLoading,
    budgetListingResponse,
    listingApiError,
    responseStatus
  } = useBudgetList()

  const isResourceEmpty = computed(() => get(responseStatus) === HttpStatusCode.NoContent)
  const isLoadedFull = computed(() => {
    const total = get(pagination).total
    return (total !== null && total <= listingBuffer.value.length) || get(isResourceEmpty)
  })

  watch(listingApiError, (error) => {
    if (error && error.status !== 404) {
      if (error.message === null) {
        push.error({ message: "Неизвестная ошибка, пожалуйста сообщите поддержке!" })
      } else {
        push.error({
          message: error.message,
          duration: Infinity,
          props: {
            retry: {
              onClick: () => loadNextPart()
            }
          }
        })
      }
    }
  })
  watch(budgetListingResponse, (listing) => {
    if (listing && listing.data.length > 0) {
      updateBufferFn(listing.data)
      setPagination(listing.pagination)
    } else {
      updateBufferFn([])
      setPagination({ ...get(pagination), total: 0 })
    }
  })
  const loadNextPart = async () => {
    setUpdateFn(bufferConcat)
    await executeListing({
      offset: get(listingBuffer, "length"),
      limit: get(pagination, "limit"),
      query: get(searchQuery) || undefined
    })
  }
  const reloadBuffer = async () => {
    setUpdateFn(bufferRewrite)
    await executeListing({
      offset: 0,
      limit: get(listingBuffer, "length"),
      query: get(searchQuery) || undefined
    })
  }

  const { createBudget, isCreating, createError, isCreateFinished } = useBudgetCreate()

  const updateBufferAfterCreate = async () => {
    setUpdateFn(bufferUnshift)
    await executeListing({
      offset: 0,
      limit: 1,
      query: get(searchQuery) || undefined
    })
  }

  const { executeDelete, isDeleting, budgetDeleteError } = useBudgetDelete()
  const deleteBudget = async (id: number) => {
    if (get(isLoadedFull)) {
      await executeDelete(id)
      bufferFilterOneConcat(id, [])
      decrementTotal()
    } else {
      await executeDelete(id)
      setUpdateFn(bufferFilterOneConcat.bind(null, id))
      await executeListing({
        offset: get(listingBuffer, "length"),
        limit: 1,
        query: get(searchQuery) || undefined
      })
    }
  }

  const { requestListDelete, budgetListDeleting, budgetDeleteListError } = useBudgetListDelete()
  const deleteByExcludedIdList = async (idList: MaybeRef<Array<number>>) => {
    await requestListDelete({ exclude_ids: get(idList), query: get(searchQuery) || undefined })
    await reloadBuffer()
  }
  const deleteBySelectedIdList = async (idList: MaybeRef<Array<number>>) => {
    await requestListDelete({ include_ids: get(idList) })
    await reloadBuffer()
  }

  const { editBudget, isEditing, editError, isEditingFinished } = useBudgetEdit()

  const updateBufferAfterEdit = async (data: BudgetFormInterface) => {
    const updatedIndex = get(listingBuffer).findIndex(({ id }) => data.id === id)
    if (updatedIndex !== -1) {
      setUpdateFn(bufferReplaceByFirst.bind(null, updatedIndex))
      await executeListing({
        offset: updatedIndex,
        limit: 1,
        query: get(searchQuery) || undefined
      })
    }
  }

  const searchListing = async (query: string) => {
    set(searchQuery, get(query))
    setPagination()
    setUpdateFn(bufferRewrite)
    await executeListing({
      offset: get(pagination, "offset"),
      limit: get(pagination, "limit"),
      query: get(searchQuery) || undefined
    })
  }

  const $reset = () => {
    set(searchQuery, undefined)
    setPagination()
    set(listingBuffer, [])
  }

  return {
    isResourceEmpty,

    searchQuery: searchQueryReadonly,
    pagination,
    idList,
    listingBuffer,
    currentOffset,
    paginationTotal,
    isLoadedFull,
    loadNextPart,
    searchListing,
    listingApiError,

    createBudget,
    isCreating,
    createError,
    isCreateFinished,
    updateBufferAfterCreate,
    editBudget,
    isEditing,
    editError,
    isEditingFinished,
    isListingLoading,
    updateBufferAfterEdit,
    deleteBudget,
    isDeleting,
    budgetDeleteError,
    budgetDeleteListError,
    deleteByExcludedIdList,
    deleteBySelectedIdList,
    isListDeleting: budgetListDeleting,

    $reset
  }
})
