import type { ItemType } from "ant-design-vue"
import type { PureAbility } from "@casl/ability"

import { inject, type ShallowRef, type VNode } from "vue"
import { computed, h } from "vue"
import { BarsOutlined, RocketOutlined, UserOutlined } from "@ant-design/icons-vue"
import { RouterLink } from "vue-router"
import { ABILITY_TOKEN } from "@casl/vue"

import RoubleIcon from "@/assets/icons/rouble.svg"
import {
  AdminLogsRouteName,
  AdminReferencesRouteName,
  AdminRouteName,
  AdminUserRouteName,
  FinanceOperationsRouteName,
  FinancePlanningRouteName,
  FinanceReportsPage,
  FinanceRouteName,
  FinanceStatistics,
  LegalProfileRouteName,
  PhysProfileRouteName,
  ProfileRouteName,
  ReferencesRouteName
} from "@/router"
import type { IPorfileStatus } from "@/modules/profile"
import { ProfileStatus } from "@/modules/profile"

const getItem = (
  label: VNode | string,
  key: string,
  disabled = false,
  icon?: VNode,
  children?: ItemType[],
  type?: "group"
): ItemType =>
  ({
    disabled,
    key,
    icon,
    children,
    label,
    type
  }) as ItemType
export const useMenuItems = (profileStatus: ShallowRef<IPorfileStatus>) => {
  const ability = inject<PureAbility>(ABILITY_TOKEN)

  return computed(() => {
    const isUnconfirmed = profileStatus.value.status === ProfileStatus.UNCONFIRMED
    let result = new Array<ItemType>()
    if (profileStatus.value.status !== ProfileStatus.UNKNOWN) {
      if (ability?.can("admin", "app") && !profileStatus.value.asAdmin) {
        result = [
          getItem("Админ. панель", AdminRouteName, isUnconfirmed, h(RocketOutlined), [
            getItem(
              h(RouterLink, { to: { name: AdminUserRouteName } }, () => "Пользователи"),
              AdminUserRouteName,
              isUnconfirmed
            ),
            getItem("Административные справочники", AdminReferencesRouteName, true),
            getItem("Лог", AdminLogsRouteName, true)
          ])
        ]
      } else {
        result = [
          getItem("Профиль", ProfileRouteName, isUnconfirmed, h(UserOutlined), [
            getItem(
              h(RouterLink, { to: "/profile/phys" }, () => "Информация о физ. лице"),
              PhysProfileRouteName,
              isUnconfirmed
            ),
            getItem(
              h(RouterLink, { to: "/profile/legal" }, () => "Информация о юр. лице"),
              LegalProfileRouteName,
              isUnconfirmed
            )
          ]),
          getItem(
            "Управление финансами",
            FinanceRouteName,
            isUnconfirmed,
            h(RoubleIcon, { width: "1em", height: "1em" }),
            [
              getItem(
                h(RouterLink, { to: { name: FinanceStatistics } }, () => "Статистика"),
                FinanceStatistics,
                isUnconfirmed
              ),
              getItem(
                h(RouterLink, { to: { name: FinanceOperationsRouteName } }, () => "Операции"),
                FinanceOperationsRouteName,
                isUnconfirmed
              ),
              getItem(
                h(RouterLink, { to: { name: FinancePlanningRouteName } }, () => "Планирование"),
                FinancePlanningRouteName,
                isUnconfirmed
              ),
              getItem(
                h(RouterLink, { to: { name: FinanceReportsPage } }, () => "Мои отчеты"),
                FinanceReportsPage,
                isUnconfirmed
              )
            ]
          ),
          getItem(
            isUnconfirmed
              ? h("div", {}, "Справочники")
              : h(RouterLink, { to: "/references" }, () => "Справочники"),
            ReferencesRouteName,
            isUnconfirmed,
            h(BarsOutlined)
          )
        ]
      }
    }
    return result
  })
}
