import type { UserDetail, UserFilters, UserSummary } from "../interface"
import type { SorterResult } from "ant-design-vue/es/table/interface"

import { defineStore } from "pinia"
import { computed, type MaybeRef, reactive, readonly, ref, watch } from "vue"
import { get, set, useDebounceFn } from "@vueuse/core"

import {
  createPaginationDefaultState,
  type OffsetPaginationDefaultDto,
  PAGINATION_DEFAULT_STATE,
  PER_PAGE_DEFAULT,
  SortOrder
} from "@/interfaces"
import { useListBufferApi, useListingSort } from "@/package/hooks"
import { getOrderBySorter } from "@/package/ui-kit"

import { UserStatus } from "../interface"
import { useUserList } from "../hooks/use-user-list"
import { useUserChangeStatus } from "../hooks/use-user-change-status"
import { useUserEdit } from "../hooks/use-user-edit"
import { useUserBatchBlock } from "../hooks/use-user-batch-block"
import { useSaveFilters } from "../hooks/use-save-filters"
import { useGetFilters } from "../hooks/use-get-filters"
import { useGetUsersSorting } from "../hooks/use-get-users-sorting"
import { useSaveUsersSorting } from "../hooks/use-save-users-sorting"

export const useUserStore = defineStore("UserAdminStore", () => {
  const buffer = ref<UserSummary[]>([])
  const idList = computed(() => buffer.value.map(({ id }) => id))
  const filters = ref<UserFilters>({})
  const isFilterExists = computed(() => Object.keys(get(filters)).length > 0)

  const pagination = reactive<OffsetPaginationDefaultDto>(createPaginationDefaultState())
  const searchQuery = ref<string>()
  const searchQueryRO = readonly(searchQuery)
  const { sortBy, sortOrder, setSort, resetSort } = useListingSort()

  const currentOffset = computed(() => pagination.offset ?? 0)
  const paginationTotal = computed(() => pagination.total ?? 0)
  const isLoadedFull = computed(
    () => pagination.total !== null && pagination.total <= buffer.value.length
  )
  const {
    bufferRO,
    setUpdateFn,
    bufferConcat,
    bufferRewrite,
    bufferReplaceByFirst,
    updateBufferFn
  } = useListBufferApi(buffer)

  const {
    isListLoading,
    requestUserList,
    userListingResponse,
    listingLoadingErrorMessage,
    listingErrorMessage
  } = useUserList()

  watch(userListingResponse, (data) => {
    if (data) {
      updateBufferFn(data.data)
      Object.assign(pagination, data.pagination)
    }
  })

  watch(listingErrorMessage, (errorMessage) => {
    const errorWithStatus = errorMessage as { STATUS: number }
    if (errorWithStatus?.STATUS === 404) {
      updateBufferFn([])
    }
  })

  const loadBufferNextPage = useDebounceFn(async () => {
    setUpdateFn(bufferConcat)
    await requestUserList({
      offset: buffer.value.length,
      limit: PER_PAGE_DEFAULT,
      query: get(searchQuery) || undefined,
      order: get(sortOrder),
      orderBy: get(sortBy),
      ...get(filters)
    })
  }, 100)

  const reloadBuffer = async () => {
    setUpdateFn(bufferRewrite)
    await requestUserList({
      offset: 0,
      limit: buffer.value.length,
      query: get(searchQuery) || undefined,
      order: get(sortOrder),
      orderBy: get(sortBy),
      ...get(filters)
    })
  }

  const requestSearch = async (requestedQuery: MaybeRef<string>) => {
    Object.assign(pagination, PAGINATION_DEFAULT_STATE)
    set(searchQuery, get(requestedQuery))
    setUpdateFn(bufferRewrite)
    await requestUserList({
      offset: pagination.offset,
      limit: pagination.limit,
      query: get(searchQuery) || undefined,
      order: get(sortOrder),
      orderBy: get(sortBy),
      ...get(filters)
    })
  }

  const applyFilters = async (filersData: MaybeRef<UserFilters>) => {
    set(filters, get(filersData))
    setUpdateFn(bufferRewrite)
    await requestUserList({
      offset: get(pagination, "offset"),
      limit: get(pagination, "limit"),
      query: get(searchQuery) || undefined,
      order: get(sortOrder),
      orderBy: get(sortBy),
      ...get(filters)
    })
  }

  const { executeSave: executeSaveFilters, isFiltersSaved } = useSaveFilters()

  const saveFilters = async () => {
    await executeSaveFilters(filters.value)
  }

  const { getFilters, isFilteredReceived, isGettingFilters } = useGetFilters()

  const getSavedFilters = async () => {
    const response = await getFilters()
    set(filters, get(response.data.value?.data ? response.data.value?.data : {}))
  }

  const { cahngeStatusErrorMessage, isStatusChanging, changeStatus } = useUserChangeStatus()
  const blockOne = async (id: MaybeRef<string | number>) => {
    const user_id = Number(get(id))
    await changeStatus({ user_id, status: UserStatus.block })

    const updatedIndex = buffer.value.findIndex(({ id }) => user_id === id)
    if (updatedIndex !== -1) {
      setUpdateFn(bufferReplaceByFirst.bind(null, updatedIndex))
      await requestUserList({
        offset: updatedIndex,
        limit: 1,
        query: get(searchQuery) || undefined,
        order: get(sortOrder),
        orderBy: get(sortBy),
        ...get(filters)
      })
    }
  }
  const activateOne = async (id: MaybeRef<string | number>) => {
    const user_id = Number(get(id))
    await changeStatus({ user_id, status: UserStatus.active })

    const updatedIndex = buffer.value.findIndex(({ id }) => user_id === id)
    if (updatedIndex !== -1) {
      setUpdateFn(bufferReplaceByFirst.bind(null, updatedIndex))
      await requestUserList({
        offset: updatedIndex,
        limit: 1,
        query: get(searchQuery) || undefined,
        order: get(sortOrder),
        orderBy: get(sortBy),
        ...get(filters)
      })
    }
  }
  const {
    requestBlockByIdList,
    requestBlockByExcludedIdList,
    batchBlockErrorMessage,
    isBatchBlockLoading
  } = useUserBatchBlock()
  const blockBySelectedIdList = async (selectedIdList: MaybeRef<Array<number | string>>) => {
    await requestBlockByIdList(selectedIdList)
    await reloadBuffer()
  }
  const blockByExcludedIdList = async (selectedIdList: MaybeRef<Array<number | string>>) => {
    await requestBlockByExcludedIdList(selectedIdList, get(searchQuery))
    await reloadBuffer()
  }

  const { isUserEditing, userEditErrorMessage, requestEditUser } = useUserEdit()
  const editOne = async (id: MaybeRef<string | number>, dto: MaybeRef<UserDetail>) => {
    const user_id = Number(get(id))
    await requestEditUser(user_id, dto)

    const updatedIndex = buffer.value.findIndex(({ id }) => user_id === id)
    if (updatedIndex !== -1) {
      setUpdateFn(bufferReplaceByFirst.bind(null, updatedIndex))
      await requestUserList({
        offset: updatedIndex,
        limit: 1,
        query: get(searchQuery) || undefined,
        order: get(sortOrder),
        orderBy: get(sortBy),
        ...get(filters)
      })
    }
  }

  const setSortOrder = async (sorter: SorterResult) => {
    const orderBy = getOrderBySorter(sorter)
    if (orderBy !== null && typeof sorter.field === "string") {
      setSort(sorter.field, orderBy)
    } else {
      resetSort()
    }
    setUpdateFn(bufferRewrite)
    await requestUserList({
      offset: get(pagination, "offset"),
      limit: get(pagination, "limit"),
      query: get(searchQuery) || undefined,
      order: get(sortOrder),
      orderBy: get(sortBy),
      ...get(filters)
    })
  }

  const { getSorting, isSortingLoading } = useGetUsersSorting()

  const getUsersSorting = async () => {
    const response = await getSorting()
    if (response?.sortBy && typeof response?.sortOrder === "string") {
      setSort(response?.sortBy, response?.sortOrder as SortOrder)
    } else {
      resetSort()
    }
  }

  const getSavedData = async () => {
    await getUsersSorting()
    await getSavedFilters()
  }

  const { executeSave: executeSaveSorting } = useSaveUsersSorting()

  const saveUsersSorting = async () => {
    await executeSaveSorting({ sortBy: sortBy.value, sortOrder: sortOrder.value })
  }

  const $reset = () => {
    Object.assign(pagination, PAGINATION_DEFAULT_STATE)
    searchQuery.value = undefined
    buffer.value = []
    set(filters, {})
    resetSort()
  }

  return {
    idList,
    listing: bufferRO,
    isLoadedFull,
    paginationTotal,
    currentOffset,

    searchQuery: searchQueryRO,
    requestSearch,
    loadBufferNextPage,
    listingLoadingErrorMessage,
    isListLoading,

    filters,
    isFilterExists,
    isFiltersSaved,
    isFilteredReceived,
    isGettingFilters,
    applyFilters,
    getSavedFilters,
    saveFilters,
    getSavedData,
    blockOne,
    activateOne,
    cahngeStatusErrorMessage,
    isStatusChanging,
    sortOrder,
    sortBy,
    setSortOrder,
    getUsersSorting,
    saveUsersSorting,
    isSortingLoading,
    blockBySelectedIdList,
    blockByExcludedIdList,
    batchBlockErrorMessage,
    isBatchBlockLoading,

    editOne,
    isUserEditing,
    userEditErrorMessage,

    $reset
  }
})
