import type { Dayjs } from "dayjs"

import dayjs from "dayjs"
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"

import type { FormValidator } from "@/package/validators"
import { RepeatPeriod } from "@/interfaces"
import type { OperationCreatRequest } from "@/modules/operation"
dayjs.extend(isSameOrBefore)

const MAX_OPERATION_AMOUNT = 150
const periodLastDateGetters: Record<RepeatPeriod, (dateFrom: Dayjs) => Dayjs> = {
  [RepeatPeriod.weekly]: (dateFrom: Dayjs) => dateFrom.clone().add(MAX_OPERATION_AMOUNT, "week"),
  [RepeatPeriod.every2weeks]: (dateFrom: Dayjs) =>
    dateFrom.clone().add(MAX_OPERATION_AMOUNT * 2, "week"),
  [RepeatPeriod.monthly]: (dateFrom: Dayjs) => dateFrom.clone().add(MAX_OPERATION_AMOUNT, "month"),
  [RepeatPeriod.every4months]: (dateFrom: Dayjs) =>
    dateFrom.clone().add(MAX_OPERATION_AMOUNT * 4, "month"),
  [RepeatPeriod.semiannual]: (dateFrom: Dayjs) =>
    dateFrom.clone().add(MAX_OPERATION_AMOUNT * 6, "month"),
  [RepeatPeriod.annual]: (dateFrom: Dayjs) => dateFrom.clone().add(MAX_OPERATION_AMOUNT, "year"),
  [RepeatPeriod.every2years]: (dateFrom: Dayjs) =>
    dateFrom.clone().add(MAX_OPERATION_AMOUNT * 2, "year"),
  [RepeatPeriod.every5years]: (dateFrom: Dayjs) =>
    dateFrom.clone().add(MAX_OPERATION_AMOUNT * 5, "year")
}

export const createDateValidator =
  (formState: Pick<OperationCreatRequest, "repeat_period">): FormValidator =>
  (_: any, value: Dayjs) =>
    new Promise((resolve, reject) => {
      if (formState.repeat_period) {
        const dateFrom = dayjs().add(1, "day")
        const dateEnd = periodLastDateGetters[formState.repeat_period](dateFrom)

        if (value.isSameOrBefore(dateEnd, "date")) {
          resolve(undefined)
        } else {
          reject(
            `Выбранная дата вне допустимого диапазона. С указанной периодичностью крайняя дата ${dateEnd.format("DD-MM-YYYY")}`
          )
        }
      } else {
        reject()
      }
    })
